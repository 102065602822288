<template>
  <div class="reporting management-page">
    <base-spinner v-if="loadingList" class="loader" />
    <div class="title" style="color: #383838; font-size: 24px;">Statements</div>
    <!-- Tabs -->
    <ul class="nav nav-tabs" id="payoutsTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
            class="nav-link active"
            id="payouts-tab"
            data-bs-toggle="tab"
            data-bs-target="#payouts"
            type="button"
            role="tab"
            aria-controls="payouts"
            aria-selected="true"
        >
          Payouts
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
            class="nav-link"
            id="statement-tab"
            data-bs-toggle="tab"
            data-bs-target="#statement"
            type="button"
            role="tab"
            aria-controls="statement"
            aria-selected="false"
        >
          Statement
        </button>
      </li>
    </ul>

    <div class="tab-content" id="payoutsTabContent" style="height: calc(100% - 80px);">
      <!-- Payouts Tab Content -->
      <div
          class="tab-pane fade show active"
          id="payouts"
          role="tabpanel"
          aria-labelledby="payouts-tab"
          style="height: 600px;"
      >
        <!-- Export Button -->
        <button class="btn btn-primary mb-2" style="margin-top: 25px" @click="exportPayoutsToExcel">
          Export Payouts to Excel
        </button>
        <!-- Payouts Grid -->
        <ag-grid-vue
            class="ag-theme-balham table mt-2"
            :columnDefs="columnDefinitionsMainTable"
            :rowData="payoutsRowData"
            :defaultColDef="defaultColDef"
            :pagination="true"
            :paginationPageSize="50"
            :sideBar="payoutsSideBar"
            @grid-ready="onPayoutsGridReady"
            @first-data-rendered="onPayoutsFirstDataRendered"
        ></ag-grid-vue>

        <!-- Modal for Settlement Group Details -->
        <base-modal v-model="showModal" :is-large="true" title="Settlement Group">
          <div class="row" style="margin-top: 35px">
            <div class="col-6">
              <p><strong>Payout ID:</strong> {{ selectedGroup.payout_details.id }}</p>
            </div>
            <div class="col-6">
              <p><strong>Processor:</strong> {{ selectedGroup.payout_details.processor }}</p>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-6">
              <p><strong>Status:</strong> {{ selectedGroup.payout_details.status }}</p>
            </div>
            <div class="col-6">
              <p><strong>Payout Amount:</strong> {{ selectedGroup.payout_details.amount }}</p>
            </div>
          </div>
          <div v-if="role === 'admin'" class="row">
            <div class="col-12">
              <p><strong>Admin Line Item Total:</strong> {{ totalAuthAmount }}</p>
            </div>
          </div>
          <div class="row" style="margin-top: 10px">
            <div class="col-12">
              <ag-grid-vue
                  class="ag-theme-balham table mt-2"
                  :columnDefs="columnDefinitionsModal"
                  :rowData="settledTransactions"
                  :defaultColDef="defaultColDef"
                  :pagination="false"
                  :immutableData="true"
                  @grid-ready="onModalGridReady"
              ></ag-grid-vue>
            </div>
          </div>
        </base-modal>
      </div>

      <!-- Statement Tab Content -->
      <div
          class="tab-pane fade"
          id="statement"
          role="tabpanel"
          aria-labelledby="statement-tab"
          style="height: calc(100% - 80px);"
      >
        <div class="row" style="margin: 25px;">
          <div class="col-6">
            <div class="form-group row">
              <!-- Year-Month Dropdown -->
              <div class="col-sm-3 col-form-label">
                <label for="yearMonthSelect">Select Month:</label>
              </div>
              <div class="col-sm-9">
                <select id="yearMonthSelect" v-model="yearMonth" @change="fetchStatementData" class="form-select">
                  <option v-for="option in yearMonthOptions" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- Export Button -->
          <div class="col-6 text-end">
            <button class="btn btn-primary mb-2" @click="exportStatementToExcel">
              Export Statement to Excel
            </button>
          </div>
        </div>
        <!-- Statement Grid -->
        <ag-grid-vue
            style="width: 100%; height: calc(100% - 80px);"
            :class="themeClass"
            :columnDefs="statementColumnDefs"
            :rowData="statementRowData"
            :defaultColDef="defaultColDef"
            :masterDetail="true"
            :detailRowHeight="getDetailRowHeight"
            :detailCellRenderer="StatementDetailRenderer"
            :detailCellRendererParams="detailCellRendererParams"
            @grid-ready="onStatementGridReady"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, shallowRef, watch } from 'vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useStore } from 'vuex';
import AgGrid from '@/components/AgGrid.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import { AgGridVue } from 'ag-grid-vue3';
import http from '@/services/http';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import StatementDetailRenderer from '@/components/statement/StatementDetailRenderer.vue';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.min.css';

const store = useStore();
const role = computed(() => store.getters.role);

// Define the function to calculate detail row height
const getDetailRowHeight = (params) => {
  // For example, calculate height based on the number of transactions and fees
  const data = params.data;
  const transactionRows = data.daily_transactions ? data.daily_transactions.length : 0;
  const feeRows = data.processor_fees ? data.processor_fees.length : 0;
  const rowHeight = 30; // Approximate height of one row in pixels

  // Calculate total rows, plus some extra space for the tabs and padding
  const maxRows = Math.max(transactionRows, feeRows);
  const totalRows = maxRows + 2; // Add 2 extra rows for headers
  const extraSpace = 120; // Space for tabs and other UI elements

  // Calculate the total height
  const totalHeight = totalRows * rowHeight + extraSpace;

  // Set a minimum and maximum height
  return Math.min(Math.max(totalHeight, 400), 3500); // Minimum 400px, maximum 3500px
}

const loadingList = ref(false);

// Payouts Tab Setup
const showModal = ref(false);
const selectedGroup = ref({});
const settledTransactions = ref([]);
const payoutsRowData = ref([]);
const defaultColDef = ref({
  flex: 1,
  resizable: true,
  sortable: true,
  filter: true,
});

// Side Bar Configuration for Payouts Grid
const payoutsSideBar = {
  hiddenByDefault: true,
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
};

// Grids
const statementGridApi = shallowRef(null);
let payoutsGridApi = null;

// Fetch Payouts Data
const onPayoutsGridReady = (params) => {
  loadingPayouts.value = true;
  updateLoadingState();
  payoutsGridApi = params.api;
  http.get('/api/payouts').then((response) => {
    payoutsRowData.value = response.data.message;
  });
};

const loadingPayouts = ref(false);
const loadingStatements = ref(false);

function updateLoadingState() {
  loadingList.value = loadingPayouts.value || loadingStatements.value;
}

const onPayoutsFirstDataRendered = (params) => {
  loadingPayouts.value = false;
  updateLoadingState();
};

const onModalGridReady = (params) => {
  params.api.sizeColumnsToFit();
};

const totalAuthAmount = computed(() => {
  if (!selectedGroup.value.transaction_details) {
    return 0;
  }
  return selectedGroup.value.transaction_details
      .reduce((sum, transaction) => {
        return sum + (parseFloat(transaction.net_amount) || 0);
      }, 0)
      .toFixed(2);
});

const columnDefinitionsMainTable = [
  {
    headerName: 'Payout Activity',
    children: [
      {
        headerName: 'Merchant',
        field: 'merchant_name',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Date',
        field: 'payout_date',
        filter: 'agDateColumnFilter',
        sort: 'desc',
      },
      {
        headerName: 'Total Amount',
        field: 'payout_details.amount',
        filter: 'agNumberColumnFilter',
        aggFunc: 'sum',
        valueFormatter: (params) => Number(params.value).toFixed(2),
      },
      {
        headerName: 'Processor',
        field: 'payout_details.processor',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Status',
        field: 'payout_details.status',
        filter: 'agTextColumnFilter',
      },
    ],
  },
  {
    headerName: 'Actions',
    children: [
      {
        headerName: 'Transactions',
        field: 'actions',
        cellRenderer: (params) => {
          if (!params.data.transaction_details) return 'N/A';
          return `<p style="font-size: 12px; text-decoration: underline; cursor: pointer;">View Detail</p>`;
        },
        onCellClicked: (params) => {
          selectedGroup.value = params.data;
          settledTransactions.value = params.data.transaction_details || [];
          showModal.value = true;
        },
        sortable: false,
        filter: false,
      },
    ],
  },
];

const columnDefinitionsModal = [
  {
    headerName: 'TransactionId',
    field: 'id',
  },
  {
    headerName: 'Created At',
    field: 'transaction_date',
  },
  {
    headerName: 'Auth Amount',
    field: 'auth_amount',
    aggFunc: 'sum',
    valueFormatter: (params) => parseFloat(params.value).toFixed(2),
  },
  {
    headerName: 'Net Amount',
    field: 'net_amount',
    valueFormatter: (params) => parseFloat(params.value).toFixed(2),
  },
  {
    headerName: 'Transaction Description',
    field: 'trans_description',
  },
];

// Statement Tab Setup
const themeClass = 'ag-theme-balham';
const statementColumnDefs = ref([
  {
    headerName: 'Merchant Name',
    field: 'merchant_name',
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    headerName: 'Month',
    field: 'month_year',
  },
  {
    headerName: 'Total Amount',
    field: 'total_amount',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
    sort: 'desc'
  },
  {
    headerName: 'Total Transactions',
    field: 'total_transactions',
  },
  {
    headerName: 'Total Fees',
    field: 'total_fees',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
  {
    headerName: 'Total Adjustments',
    field: 'total_adjustments',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
  {
    headerName: 'Chargebacks Total',
    field: 'charge_backs_and_reversals_total',
    valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
  },
]);

const statementRowData = ref([]);
const gridApi = shallowRef(null);

// Year-Month selection for Statement Tab
const yearMonthOptions = ref([]); // Options for the past 2 years
const yearMonth = ref(''); // Selected year-month

function getPreviousYearMonth() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
}

function generateYearMonthOptions() {
  const options = [];
  const today = new Date();
  const startDate = new Date(today.getFullYear() - 2, today.getMonth(), 1); // 2 years ago

  while (startDate <= today) {
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, '0');
    options.push(`${year}-${month}`);
    startDate.setMonth(startDate.getMonth() + 1);
  }

  // Reverse the array so the most recent dates are first
  options.reverse();
  yearMonthOptions.value = options;

  // Set default selected value to the previous month
  if (!yearMonth.value) {
    yearMonth.value = getPreviousYearMonth();
  }
}

const fetchStatementData = () => {
  if (!yearMonth.value) {
    yearMonth.value = getPreviousYearMonth();
  }
  loadingStatements.value = true;
  updateLoadingState();
  http.get(`/api/statement?year-month=${yearMonth.value}`).then((response) => {
    statementRowData.value = response.data.message;
    loadingStatements.value = false;
    updateLoadingState();
  });
};

onMounted(() => {
  generateYearMonthOptions();
});

const detailCellRendererParams = {
  getDetailRowData: function(params) {
    params.successCallback(params.data);
  },
};

const onStatementGridReady = (params) => {
  statementGridApi.value = params.api;
  fetchStatementData();
};


const exportPayoutsToExcel = async () => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Payouts');

  // Get the column headers from the main grid
  const mainGridColumnDefs = columnDefinitionsMainTable.flatMap((group) => group.children);
  const mainGridHeaders = mainGridColumnDefs.map((colDef) => ({
    header: colDef.headerName,
    key: colDef.field,
    width: 20,
  }));

  // Add columns to the worksheet (without headers)
  worksheet.columns = [
    { header: '', key: 'indent', width: 5 }, // Indent column
    ...mainGridHeaders.map((col) => ({ key: col.key, width: 20 })), // No header property
  ];

  // Define styles
  const headerStyle = {
    font: { bold: true, color: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center' },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } },
  };

  const masterDataStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9E1F2' } },
  };

  const sectionHeaderStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC6EFCE' } },
  };

  const sectionDataHeaderStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8CBAD' } },
  };

  const sectionDataStyle = {
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFF2CC' } },
  };

  // Add header row manually
  const headerRow = worksheet.addRow(['', ...mainGridHeaders.map((col) => col.header)]);
  headerRow.eachCell((cell, colNumber) => {
    if (colNumber > 1) {
      Object.assign(cell, { style: headerStyle });
    }
  });

  // Iterate over all payouts
  for (const payout of payoutsRowData.value) {
    // Add the master data row
    const rowData = mainGridHeaders.map((colDef) => {
      const field = colDef.key;
      let value;

      if (field.includes('.')) {
        // Handle nested fields like 'payout_details.amount'
        const fields = field.split('.');
        value = payout;
        fields.forEach((f) => {
          value = value ? value[f] : '';
        });
      } else {
        value = payout[field];
      }

      return colDef.valueFormatter ? colDef.valueFormatter({ value }) : value;
    });

    const masterRow = worksheet.addRow(['', ...rowData]);
    masterRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        Object.assign(cell, { style: masterDataStyle });
      }
    });

    // Insert an empty row
    worksheet.addRow([]);

    // Transaction Details Section
    const transactionDetails = payout.transaction_details || [];

    if (transactionDetails.length > 0) {
      // Insert "Transaction Details" header, indented
      const transDetailsHeaderRow = worksheet.addRow(['', 'Transaction Details']);
      transDetailsHeaderRow.getCell(2).style = sectionHeaderStyle;

      // Insert headers for transaction details, indented
      const transDetailsHeaders = [
        '',
        'Transaction ID',
        'Transaction Date',
        'Auth Amount',
        'Net Amount',
        'Transaction Description',
      ];
      const transDetailsHeaderRow2 = worksheet.addRow(transDetailsHeaders);
      transDetailsHeaderRow2.eachCell((cell, colNumber) => {
        if (colNumber > 1) {
          Object.assign(cell, { style: sectionDataHeaderStyle });
        }
      });

      // Add transaction details data
      transactionDetails.forEach((row) => {
        const rowData = [
          '',
          row.id,
          new Date(row.transaction_date).toLocaleDateString(),
          parseFloat(row.auth_amount),
          parseFloat(row.net_amount),
          row.trans_description,
        ];
        const dataRow = worksheet.addRow(rowData);
        dataRow.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            Object.assign(cell, { style: sectionDataStyle });

            // Apply currency format to monetary columns
            if (['Auth Amount', 'Net Amount'].includes(transDetailsHeaders[colNumber - 1])) {
              cell.numFmt = '"$"#,##0.00';
            }
          }
        });
      });

      // Insert an empty row
      worksheet.addRow([]);
    }

    // Insert an empty row before moving to the next payout
    worksheet.addRow([]);
  }

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    if (!column.width || column.width < 15) {
      column.width = 15;
    }
  });

  // Write to file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Payouts.xlsx');
  });
};

// Helper function to apply styles to the worksheet
// Helper function to apply styles to the worksheet
function applyStylesToWorksheet(worksheet, data, transactionSections) {
  // Define styles
  const headerStyle = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
  };

  const masterDataStyle = {
    font: { bold: true },
  };

  const transactionHeaderStyle = {
    font: { bold: true },
    fill: { fgColor: { rgb: 'D9D9D9' } }, // Light gray background
  };

  const transactionDataStyle = {
    fill: { fgColor: { rgb: 'F2F2F2' } }, // Very light gray background
  };

  const borderStyle = {
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
    },
  };

  // Apply header style to master headers
  const headerRowIndex = 0;
  const headerRow = data[headerRowIndex];
  for (let C = 0; C < headerRow.length; ++C) {
    const cellRef = XLSX.utils.encode_cell({ r: headerRowIndex, c: C });
    const cell = worksheet[cellRef];
    if (cell) {
      cell.s = headerStyle;
    }
  }

  // Apply style to master data rows
  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    // If the row is a master data row (not empty and not a transaction header)
    if (row.length > 0 && row[0] && row[0] !== '') {
      for (let j = 0; j < row.length; j++) {
        const cellRef = XLSX.utils.encode_cell({ r: i, c: j });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.s = masterDataStyle;
        }
      }
    }
  }

  // Apply styles to transaction sections
  transactionSections.forEach((section) => {
    // Apply border around the transaction detail section
    for (let R = section.startRow; R <= section.endRow; ++R) {
      for (let C = 1; C <= 5; ++C) { // Columns B to F
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellRef];
        if (cell) {
          // Apply border to cell
          if (!cell.s) cell.s = {};
          cell.s = { ...cell.s, ...borderStyle };
        }
      }
    }

    // Apply style to transaction headers
    const transactionHeaderRowIndex = section.startRow;
    for (let C = 1; C <= 5; ++C) {
      const cellRef = XLSX.utils.encode_cell({ r: transactionHeaderRowIndex, c: C });
      const cell = worksheet[cellRef];
      if (cell) {
        cell.s = { ...cell.s, ...transactionHeaderStyle };
      }
    }

    // Apply style to transaction data rows
    for (let R = section.startRow + 1; R <= section.endRow; ++R) {
      for (let C = 1; C <= 5; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.s = { ...cell.s, ...transactionDataStyle };
        }
      }
    }
  });
}

// Helper function to calculate column widths
function calculateColumnWidths(data) {
  const colWidths = [];
  data.forEach((row) => {
    row.forEach((cell, idx) => {
      const cellValue = cell ? cell.toString() : '';
      const cellLength = cellValue.length;

      if (!colWidths[idx]) {
        colWidths[idx] = { wch: cellLength + 2 }; // Adding padding
      } else if (cellLength + 2 > colWidths[idx].wch) {
        colWidths[idx].wch = cellLength + 2; // Adding padding
      }
    });
  });

  // Optionally, set a minimum or maximum width
  colWidths.forEach((col) => {
    if (col.wch < 15) col.wch = 15; // Minimum width
    if (col.wch > 50) col.wch = 50; // Maximum width
  });

  return colWidths;
}

// Export Statement to Excel
// Export Statement to Excel (Modified to access detail data from master data)
const exportStatementToExcel = async () => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Statement');

  // Get the column headers from the main grid
  const mainGridColumnDefs = statementGridApi.value.getColumnDefs();
  const mainGridHeaders = mainGridColumnDefs.map((colDef) => ({
    header: colDef.headerName,
    key: colDef.field,
    width: 20,
  }));

  // Add columns to the worksheet (without headers)
  worksheet.columns = [
    { header: '', key: 'indent', width: 5 }, // Indent column
    ...mainGridHeaders.map((col) => ({ key: col.key, width: 20 })),
  ];

  // Define styles
  const headerStyle = {
    font: { bold: true, color: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center' },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } },
  };

  const masterDataStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9E1F2' } },
  };

  const sectionHeaderStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC6EFCE' } },
  };

  const sectionDataHeaderStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8CBAD' } },
  };

  const sectionDataStyle = {
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFF2CC' } },
  };

  // Add header row manually
  const headerRow = worksheet.addRow(['', ...mainGridHeaders.map((col) => col.header)]);
  headerRow.eachCell((cell, colNumber) => {
    if (colNumber > 1) {
      Object.assign(cell, { style: headerStyle });
    }
  });

  // Iterate over all master rows
  for (const statement of statementRowData.value) {
    // Add the master data row
    const rowData = mainGridHeaders.map((colDef) => {
      const field = colDef.key;
      const value = statement[field];
      return colDef.valueFormatter ? colDef.valueFormatter({ value }) : value;
    });

    const masterRow = worksheet.addRow(['', ...rowData]);
    masterRow.eachCell((cell, colNumber) => {
      if (colNumber > 1) {
        Object.assign(cell, { style: masterDataStyle });
      }
    });

    // Insert an empty row
    worksheet.addRow([]);

    // Daily Transactions Section
    const dailyTransactions = statement.daily_transactions || [];

    if (dailyTransactions.length > 0) {
      // Insert "Daily Transactions" header, indented
      const dailyTransHeaderRow = worksheet.addRow(['', 'Daily Transactions']);
      dailyTransHeaderRow.getCell(2).style = sectionHeaderStyle;

      // Insert headers for daily transactions, indented
      const dailyTransHeaders = [
        '',
        'Transaction Day',
        'Total Transactions',
        'Total Processed',
        'Total Fees',
        'Total Adjustments',
        'Total Chargebacks',
      ];
      const dailyTransHeaderRow2 = worksheet.addRow(dailyTransHeaders);
      dailyTransHeaderRow2.eachCell((cell, colNumber) => {
        if (colNumber > 1) {
          Object.assign(cell, { style: sectionDataHeaderStyle });
        }
      });

      // Add daily transactions data
      dailyTransactions.forEach((row) => {
        const rowData = [
          '',
          new Date(row.transaction_day).toLocaleDateString(),
          row.total_transactions,
          parseFloat(row.total_processed),
          parseFloat(row.total_fees),
          parseFloat(row.total_adjustments),
          parseFloat(row.total_chargebacks),
        ];
        const dataRow = worksheet.addRow(rowData);
        dataRow.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            Object.assign(cell, { style: sectionDataStyle });

            // Apply currency format only to monetary columns
            if (['Total Processed', 'Total Fees', 'Total Adjustments', 'Total Chargebacks'].includes(dailyTransHeaders[colNumber - 1])) {
              cell.numFmt = '"$"#,##0.00';
            } else if (dailyTransHeaders[colNumber - 1] === 'Total Transactions') {
              // Ensure Total Transactions is formatted as an integer
              cell.numFmt = '0';
            }
          }
        });
      });

      // Insert an empty row
      worksheet.addRow([]);
    }

    // Processor Fees Section
    const processorFees = statement.processor_fees || [];

    if (processorFees.length > 0) {
      // Insert "Processor Fees" header, indented
      const procFeesHeaderRow = worksheet.addRow(['', 'Processor Fees']);
      procFeesHeaderRow.getCell(2).style = sectionHeaderStyle;

      // Insert headers for processor fees, indented
      const procFeesHeaders = [
        '',
        'Statement Date',
        'Description',
        'Item Count',
        'Volume',
        'Rate 1',
        'Rate 2',
        'Total',
      ];
      const procFeesHeaderRow2 = worksheet.addRow(procFeesHeaders);
      procFeesHeaderRow2.eachCell((cell, colNumber) => {
        if (colNumber > 1) {
          Object.assign(cell, { style: sectionDataHeaderStyle });
        }
      });

      // Add processor fees data
      processorFees.forEach((row) => {
        const rowData = [
          '',
          new Date(row.STATEMENT_DATE).toLocaleDateString(),
          row.DETAIL_DESCRIPTION,
          row.ITEM_COUNT,
          parseFloat(row.VOLUME),
          parseFloat(row.R_RATE1),
          parseFloat(row.R_RATE2),
          parseFloat(row.R_TOTAL),
        ];
        const dataRow = worksheet.addRow(rowData);
        dataRow.eachCell((cell, colNumber) => {
          if (colNumber > 1) {
            Object.assign(cell, { style: sectionDataStyle });

            // Get headerText
            const headerText = procFeesHeaders[colNumber - 1];

            // Apply currency format only to monetary columns
            if (['Item Count'].includes(headerText)) {
              // Ensure Item Count is formatted as an integer
              cell.numFmt = '0';
            } else if (['Volume', 'Rate 1', 'Rate 2'].includes(headerText)) {
              // Format Volume and Rates appropriately
              cell.numFmt = '#,##0.00';
            }
          }
        });
      });

      // Insert an empty row
      worksheet.addRow([]);
    }

    // Insert an empty row before moving to the next merchant
    worksheet.addRow([]);
  }

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    if (!column.width || column.width < 15) {
      column.width = 15;
    }
  });

  // Write to file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Statement.xlsx');
  });
};

function applyStatementStylesToWorksheet(worksheet, data) {
  // Define styles
  const headerStyle = {
    font: { bold: true, color: { rgb: 'FFFFFF' } },
    alignment: { horizontal: 'center' },
    fill: { fgColor: { rgb: '4F81BD' } }, // Dark blue background
  };

  const masterDataStyle = {
    font: { bold: true },
    fill: { fgColor: { rgb: 'D9E1F2' } }, // Light blue background
  };

  const sectionHeaderStyle = {
    font: { bold: true },
    fill: { fgColor: { rgb: 'C6EFCE' } }, // Light green background
  };

  const sectionDataHeaderStyle = {
    font: { bold: true },
    fill: { fgColor: { rgb: 'F8CBAD' } }, // Light orange background
  };

  const sectionDataStyle = {
    fill: { fgColor: { rgb: 'FFF2CC' } }, // Light yellow background
  };

  // Apply header style to master headers
  const headerRowIndex = 0;
  const headerRow = data[headerRowIndex];
  for (let C = 0; C < headerRow.length; ++C) {
    const cellRef = XLSX.utils.encode_cell({ r: headerRowIndex, c: C });
    const cell = worksheet[cellRef];
    if (cell) {
      cell.s = headerStyle;
    }
  }

  let rowIndex = 1;
  while (rowIndex < data.length) {
    const row = data[rowIndex];

    // Check if it's a master data row (Merchant Name, etc.)
    if (row.length > 0 && row[0] && row[0] !== '') {
      // Apply master data style
      for (let C = 0; C < row.length; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: C });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.s = masterDataStyle;
        }
      }
    } else if (row.length > 1 && row[1] && (row[1] === 'Daily Transactions' || row[1] === 'Processor Fees')) {
      // Apply section header style
      const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: 1 });
      const cell = worksheet[cellRef];
      if (cell) {
        cell.s = sectionHeaderStyle;
      }
    } else if (row.length > 1 && row[1] && (row[1] === 'Transaction Day' || row[1] === 'Statement Date')) {
      // Apply section data header style
      for (let C = 1; C < row.length; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: C });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.s = sectionDataHeaderStyle;
        }
      }
    } else if (row.length > 1 && row[1]) {
      // Apply section data style
      for (let C = 1; C < row.length; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: C });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.s = sectionDataStyle;
        }
      }
    }
    rowIndex++;
  }
}

// Watch for changes in yearMonth to fetch new data
watch(yearMonth, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    fetchStatementData();
  }
});
</script>

<style lang="scss" scoped>
/* Your existing styles */
[v-cloak] {
  display: none;
}

.loader {
  z-index: 2;
}

.table {
  height: 70vh !important;
}

.subs-btn {
  height: 40px;
  padding: 0;
  background: #fff;
  border-color: #f6951e !important;
  color: #f6951e !important;

  &:hover {
    background: var(--c-grey-light) !important;
  }
}

@media screen and (max-width: 768px) {
  .reporting-filters {
    flex-direction: column;
  }

  .reporting-filters-container {
    flex-direction: column-reverse;
  }

  .report-input {
    width: 50%;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

::v-deep(.report-buttons) {
  @media screen and (max-width: 768px) {
    .btn {
      width: 50%;
    }
  }
}
</style>

<style lang="scss">
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.min.css";

/* Additional styles for the reporting page */
.reporting {
  .status-value {
    &.Failed {
      background: #ffe4e4;
      color: #b3322c;
    }

    &.Success {
      color: var(--c-success);
      background: #ddffef;
    }
  }

  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
</style>
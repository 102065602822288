<template>
  <view-report-modal
    v-model="showViewModal"
    :details="showViewDetails"
    @keydown.esc="closeTransaction"
    @open-refund="showRefundModal = true"
  />

  <base-modal v-model="showRefundModal">
    <refund-modal
      :transaction="transactionDetails"
      @update="handleRefundSuccess"
    />
  </base-modal>

  <form
    v-show="screenMode === 'search'"
    class="mt-4"
    @submit.prevent="formDataCallback"
  >
    <div class="container">
      <!-- hide this until there is so much stuff you have to scroll to see both -->
      <div class="row my-3 d-none">
        <div class="col-12 col-sm-6 col-xl-4">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formSubmissionLoading"
          >
            Submit
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-xl-4">
          <div class="card">
            <div class="card-header">Core Details</div>
            <div class="card-body">
              <h6 class="card-title">Date Range</h6>
              <div class="mb-3 row">
                <div class="col-4">
                  <label for="customDateRangeStart" class="form-label">
                    Start Date
                  </label>
                </div>
                <div class="col-8">
                  <div class="float-start" style="width: calc(100% - 24px)">
                    <input
                      id="customDateRangeStart"
                      ref="customDateRangeStart"
                      v-model="customDateRangeStartModel"
                      name="customDateRangeStart"
                      type="date"
                      class="form-control"
                      :required="true"
                    />
                  </div>
                  <div class="float-end">
                    <input
                      :id="'customDateRangeStart' + CHECKBOX_SUFFIX"
                      :name="'customDateRangeStart' + CHECKBOX_SUFFIX"
                      type="checkbox"
                      class="form-check-input"
                      :checked="true"
                      :disabled="true"
                    />
                    <input
                      :name="'customDateRangeStart' + CHECKBOX_SUFFIX"
                      :checked="true"
                      type="checkbox"
                      class="d-none"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-4">
                  <label for="customDateRangeEnd" class="form-label">
                    End Date
                  </label>
                </div>
                <div class="col-8">
                  <div class="float-start" style="width: calc(100% - 24px)">
                    <input
                      id="customDateRangeEnd"
                      ref="customDateRangeEnd"
                      v-model="customDateRangeEndModel"
                      name="customDateRangeEnd"
                      type="date"
                      class="form-control"
                      :required="true"
                    />
                  </div>
                  <div class="float-end">
                    <input
                      :id="'customDateRangeEnd' + CHECKBOX_SUFFIX"
                      :name="'customDateRangeEnd' + CHECKBOX_SUFFIX"
                      type="checkbox"
                      class="form-check-input"
                      :checked="true"
                      :disabled="true"
                    />
                    <input
                      :name="'customDateRangeEnd' + CHECKBOX_SUFFIX"
                      :checked="true"
                      type="checkbox"
                      class="d-none"
                    />
                  </div>
                </div>
              </div>

              <h6 class="card-title">Predefined Date Range</h6>
              <div class="mb-3 row">
                <div class="col-4">
                  <label for="predefinedDateRange" class="form-label">
                    Select Period
                  </label>
                </div>
                <div class="col-8">
                  <select
                    id="predefinedDateRange"
                    ref="predefinedDateRange"
                    name="predefinedDateRange"
                    class="form-select"
                    @change="updateDatesToMatchPeriod"
                  >
                    <!--
                    <option selected value="">(Select a period)</option>
                    -->
                    <option selected value="day">Today</option>
                    <option value="week">This week</option>
                    <option value="month">This month</option>
                    <option value="last-day">Yesterday</option>
                    <option value="last-week">Last week</option>
                    <option value="last-month">Last month</option>
                  </select>
                </div>
              </div>

              <!--
              <hr />
              <div class="mb-3 row">
                <div class="col-4">
                  <label class="form-label">Amount Range</label>
                </div>
                <div class="col-8">
                  <div class="float-start" style="width: calc(100% - 24px)">
                    $
                    <input
                      id="amountStart"
                      ref="amountEnd"
                      name="amountStart"
                      type="number"
                      class="form-control currency d-inline"
                      style="width: 80px"
                      min="0"
                      step="0.01"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                    />
                    to $
                    <input
                      id="amountEnd"
                      ref="amountEnd"
                      name="amountEnd"
                      type="number"
                      class="form-control currency d-inline"
                      style="width: 80px"
                      min="0"
                      step="0.01"
                      data-number-to-fixed="2"
                      data-number-stepfactor="100"
                    />
                  </div>
                  <div class="float-end">
                    <input
                      :id="'amount' + CHECKBOX_SUFFIX"
                      :name="'amount' + CHECKBOX_SUFFIX"
                      type="checkbox"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div class="mb-3 row">
                <div class="col-4">
                  <label for="paymentMethodNumber" class="form-label">
                    CC/ACH Last 4
                  </label>
                </div>
                <div class="col-8">
                  <div class="float-start" style="width: calc(100% - 24px)">
                    <input
                      id="paymentMethodNumber"
                      ref="paymentMethodNumber"
                      name="paymentMethodNumber"
                      type="number"
                      class="form-control currency"
                      min="0"
                      step="1"
                      data-number-to-fixed="0"
                      data-number-stepfactor="1"
                    />
                  </div>
                  <div class="float-end">
                    <input
                      :id="'paymentMethodNumber' + CHECKBOX_SUFFIX"
                      :name="'paymentMethodNumber' + CHECKBOX_SUFFIX"
                      type="checkbox"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>

              <hr />
              <div class="mb-3 row">
                <div class="col-4">
                  <label for="transactionId" class="form-label">
                    Transaction ID
                  </label>
                </div>
                <div class="col-8">
                  <div class="float-start" style="width: calc(100% - 24px)">
                    <input
                      id="transactionId"
                      ref="transactionId"
                      name="transactionId"
                      type="text"
                      class="form-control currency"
                      min="0"
                      step="1"
                      data-number-to-fixed="0"
                      data-number-stepfactor="1"
                    />
                  </div>
                  <div class="float-end">
                    <input
                      :id="'transactionId' + CHECKBOX_SUFFIX"
                      :name="'transactionId' + CHECKBOX_SUFFIX"
                      type="checkbox"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 d-none">
          <div class="card">
            <div class="card-header">Transaction Details</div>
            <div class="card-body">
              <template
                v-for="(field, index) in [
                  {
                    name: 'transactionType',
                    display: 'Transaction Type'
                  },
                  {
                    name: 'transactionResult',
                    display: 'Transaction Result',
                    type: 'select',
                    options: [
                      { id: true, label: 'Success' },
                      { id: false, label: 'Failure' }
                    ]
                  },
                  {
                    name: 'paymentType',
                    display: 'Payment Type'
                  },
                  {
                    name: 'cardType',
                    display: 'Card Type'
                  },
                  {
                    name: 'cardPresent',
                    display: 'Card Present',
                    type: 'select',
                    options: [
                      { id: 'CP', label: 'Card Present' },
                      { id: 'CNP', label: 'Card Not Present' }
                    ]
                  },
                  {
                    name: 'batchId',
                    display: 'Batch ID'
                  }
                ]"
                :key="field.name"
              >
                <hr v-if="index !== 0" />
                <div class="mb-3 row">
                  <div class="col-4">
                    <label :for="field.name" class="form-label small">{{
                      field.display
                    }}</label>
                  </div>
                  <div class="col-8">
                    <div class="float-start" style="width: calc(100% - 24px)">
                      <input
                        v-if="!field.type || field.type === 'text'"
                        :id="field.name"
                        :name="field.name"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <select
                        v-if="field.type === 'select'"
                        :id="field.name"
                        :name="field.name"
                        class="form-select form-select-sm"
                      >
                        <option selected value=""></option>
                        <option
                          v-for="option in field.options"
                          :key="option.id"
                          :value="option.id"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                    <div class="float-end">
                      <input
                        :id="field.name + CHECKBOX_SUFFIX"
                        :name="field.name + CHECKBOX_SUFFIX"
                        type="checkbox"
                        class="form-check-input"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-xl-4 d-none">
          <div class="card">
            <div class="card-header">Custom Fields</div>
            <div class="card-body">
              <template
                v-for="(field, index) in customFields"
                :key="field.name"
              >
                <hr v-if="index !== 0" />
                <div class="mb-3 row">
                  <div class="col-4">
                    <label :for="field.name" class="form-label small">{{
                      field.display
                    }}</label>
                  </div>
                  <div class="col-8">
                    <div class="float-start" style="width: calc(100% - 24px)">
                      <input
                        v-if="!field.type || field.type === 'text'"
                        :id="field.name"
                        :name="'customField:' + field.name"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <select
                        v-if="field.type === 'select'"
                        :id="field.name"
                        :name="'customField:' + field.name"
                        class="form-select form-select-sm"
                      >
                        <option selected value=""></option>
                        <option
                          v-for="option in field.options"
                          :key="option.id"
                          :value="option.id"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                    <div class="float-end">
                      <input
                        :id="field.name + CHECKBOX_SUFFIX"
                        :name="'customField:' + field.name + CHECKBOX_SUFFIX"
                        type="checkbox"
                        class="form-check-input"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-xl-4 d-none">
          <div class="card">
            <div class="card-header">Debug</div>
            <div class="card-body">
              <p class="card-text">{{ formData }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 col-sm-6 col-xl-4">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formSubmissionLoading"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
  <div v-show="screenMode === 'results'">
    <div class="container">
      <div class="row my-3">
        <div class="col-12 col-sm-6 col-xl-4">
          <button
            type="button"
            class="btn btn-primary"
            @click="refineSearch"
          >
            Refine Search
          </button>
        </div>
      </div>
      <div class="row my-3">
        <!--<div class="col-12 col-sm-6 col-xl-4">-->
        <div class="col-12">
          <div class="card">
            <div class="card-header">Search Results</div>
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <h6 class="card-title">Results</h6>
                </div>
                <div class="col">
                  <span class="point btn float-md-end" @click="() => agGridApi.exportDataAsExcel({ fileName: 'Transaction Search.xlsx' })">
                    <export :size="20" />
                    <span class="refresh-text">Export Excel</span>
                  </span>
                </div>
              </div>
              <!--
              <div v-for="res in results" :key="res.id">
                {{ res }}
              </div>
              -->
              <!--row-selection="single"-->
              <!--group-display-type="multipleColumns"-->
              <!--:default-col-def="defaultColDef"-->
              <!--:column-defs="columnDefs"-->
              <!--:filter-options="filterOptions"-->
              <!--:side-bar="true"-->
              <!--:status-bar="{}"-->
              <!--:pagination="pagination"-->
              <!--:pagination-auto-page-size="false"-->
              <ag-grid-vue
                ref="agGrid"
                :style="'width:' + '100%' + '; height:' + '75vh' + ';'"
                class="ag-theme-balham"
                :grid-options="{
                  domLayout: 'normal',
                  rowSelection: 'single',
                  groupDisplayType: 'multipleColumns',
                  defaultColDef: {
                    enableRowGroup: true,
                    enablePivot: true,
                    enableValue: true,
                    sortable: true,
                    filter: true,
                    resizable: true,
                    useValueFormatterForExport: true
                  },
                  columnDefs: columnDefs,
                  sideBar: true,
                  // statusBar: {},
                  pagination: true,
                  paginationPageSize: 500,
                  paginationAutoPageSize: true,
                  rowGroupPanelShow: 'onlyWhenGrouping',
                  // autoGroupColumnDef: {
                  //   valueFormatter: ({ value, data }) => 'Grouped ' + value,
                  //   groupMaintainOrder: true,
                  //   field: 'model',
                  //   cellRenderer: 'agGroupCellRenderer',
                  //   cellRendererParams: {
                  //     checkbox: false
                  //   }
                  // },
                  enableRangeSelection: false,
                  excelStyles: [
                    {
                      id: 'dateType',
                      dataType: 'DateTime',
                      numberFormat: { format: 'yyyy-mm-dd hh:mm:ss' }
                    }
                  ],
                  // aggFuncs: ,
                  onGridReady(event) {
                    console.log('ReportingSearchPage.vue onGridReady', event);
                    agGridApi = event.api
                    agGridColumnApi = event.columnApi
                  },
                  onFirstDataRendered(event) {
                    console.log('ReportingSearchPage.vue onFirstDataRendered', event);
                  },
                  onFilterChanged(event) {
                    console.log('ReportingSearchPage.vue onFilterChanged', event);
                  },
                  onGridColumnsChanged(event) {
                    console.log('ReportingSearchPage.vue onGridColumnsChanged', event);
                  },
                  onRowClicked(event) {
                    console.log('ReportingSearchPage.vue onRowClicked', event);
                  },
                  /**
                   * @param {import('ag-grid-community').RowDoubleClickedEvent<ReportingSearchTransaction>} event
                   */
                  onRowDoubleClicked(event) {
                    console.log('ReportingSearchPage.vue onRowDoubleClicked', event);
                    openViewModal(event.data.transaction_details);
                  },
                }"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import http from '@/services/http';
import { DateTime } from 'luxon';
import { useStore } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import { useRouter } from 'vue-router';
import ViewReportModal from '@/components/reporting/ViewReportModal.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import RefundModal from '@/components/reporting/RefundModal.vue';
import Export from 'vue-material-design-icons/Export.vue';

const CHECKBOX_SUFFIX = 'Use';

/**
 * @typedef {{
 *   id: string
 *   date_time: string
 *   customer: string
 *   transaction_type: string
 *   payment_type: string
 *   account: string
 *   amount: string
 *   status: string
 *   transaction_details: ReportingSearchTransactionDetails
 * }} ReportingSearchTransaction
 */

/**
 * @typedef {{
 *   created_at: string
 *   updated_at: string
 *   amount: string
 *   data: ReportingSearchTransactionDetailsData
 *   client: ReportingSearchTransactionDetailsClient
 * }} ReportingSearchTransactionDetails
 */

/**
 * @typedef {{
 *   api_response: {
 *     original_transaction_id?: string
 *     original_transaction?: ReportingSearchTransactionDetails
 *   } & Record<string, string | Object>
 *   third_party_api_response: Record<string, string | Object>
 * }} ReportingSearchTransactionDetailsData
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   merchant: ReportingSearchTransactionDetailsClientMerchant
 * }} ReportingSearchTransactionDetailsClient
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 * }} ReportingSearchTransactionDetailsClientMerchant
 */

/**
 * @type {Array<import("ag-grid-community").ColDef | import("ag-grid-community").ColGroupDef>}
 */
const columnDefs = [
  {
    headerName: 'Transaction ID',
    colId: 'transaction_id', // 'id',
    filter: 'agTextColumnFilter',
    valueGetter: (params) => params.data.id
  },
  {
    headerName: 'Original Transaction ID',
    colId: 'original_transaction_id', // 'id',
    filter: 'agTextColumnFilter',
    valueGetter: (params) => {
      console.log('valueGetter')
      return params?.data?.transaction_details?.data?.api_response?.original_transaction_id;
    },
    /**
     * @param {import("ag-grid-community").CellClickedEvent<ReportingSearchTransaction, *>} params
     */
    onCellClicked(params) {
      if (params?.data?.transaction_details?.data?.api_response?.original_transaction)
        openViewModal(params?.data?.transaction_details?.data?.api_response?.original_transaction)
    },
  },
  {
    headerName: 'Date',
    colId: 'date_time',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.date_time
  },
  {
    headerName: 'Customer',
    colId: 'customer',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.customer
  },
  {
    initialWidth: 125,
    headerName: 'Transaction Type',
    colId: 'transaction_type',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.transaction_type
  },
  {
    initialWidth: 125,
    headerName: 'Payment Type',
    colId: 'payment_type',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.payment_type
  },
  {
    initialWidth: 75,
    headerName: 'Account',
    colId: 'account',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.account
  },
  {
    initialWidth: 75,
    headerName: 'Amount',
    colId: 'amount',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.amount
  },
  {
    initialWidth: 125,
    headerName: 'Status',
    colId: 'status',
    filter: 'agTextColumnFilter',
    /** @param {import("ag-grid-community").ValueGetterParams<ReportingSearchTransaction>} params */
    valueGetter: (params) => params.data.status
  }
  /*
  {
    headerName: 'Details',
    colId: 'transaction_details',
    filter: 'agTextColumnFilter',
    // valueGetter: params => params.data.transaction_details,
    cellRenderer: params => `<code>${JSON.stringify(params.data.transaction_details)}</code>`,
  },
 */
];

const store = useStore();
const router = useRouter();

/**
 * @typedef {'search' | 'results'} ScreenMode
 */

/**
 * @type {import('vue').Ref<ScreenMode>}
 */
const screenMode = ref('search');
const customDateRangeStart = ref();
const customDateRangeStartModel = ref();
const customDateRangeEnd = ref();
const customDateRangeEndModel = ref();
const predefinedDateRange = ref();
/**
 * @type {import("vue").Ref<Array<ReportingSearchTransaction>>}
 */
const results = ref([]);
const agGrid = ref();
/** @type {import("vue").Ref<import("ag-grid-community").GridApi>} */
const agGridApi = ref();
/** @type {import("vue").Ref<import("ag-grid-community").ColumnApi>} */
const agGridColumnApi = ref();

const showViewModal = ref(false);
const showRefundModal = ref(false);

/**
 * @type {import("vue").Ref<null | ReportingSearchTransactionDetails>}
 */
const transactionDetails = ref(null);
/**
 * @type {import("vue").Ref<null | ReportingTuple>}
 */
const showViewDetails = ref(null);

const formSubmissionLoading = ref(false);
const formData = ref();

/**
 * @param {ReportingSearchTransactionDetails} trx
 * @returns {ReportingTuple}
 */
function trxToTuple(trx) {
  return [trx.created_at, trx, trx.client.merchant.name, trx.client.merchant.parent?.name || 'TriplePlayPay'];
}

/**
 * @param {ReportingSearchTransactionDetails} t
 */
function openViewModal(t) {
  const tuple = trxToTuple(t);

  showViewModal.value = true;
  showViewDetails.value = tuple;
  transactionDetails.value = t;
}

function closeTransaction() {
  showViewModal.value = false;
  showRefundModal.value = false;
}

function handleRefundSuccess() {
  showViewModal.value = false;
  showRefundModal.value = false;
  transactionDetails.value = null;
}

const customFields = computed(() => {
  if (!store.state.allMerchantConfigs) return [];
  let cfValues = Object.values(store.state.allMerchantConfigs)
    .map((e) => e?.configuration?.customizations?.custom_fields)
    .filter(Boolean)
    .flatMap((e) => e);
  let cfUnique = Array.from(new Set(cfValues.map((e) => e.name)));
  return cfUnique.map((e) => ({ name: e, display: e, type: 'text' }));
});

watch(formData, async () => {
  console.log('form data changed', formData.value);
  formSubmissionLoading.value = true;
  await router.push('/reporting/search?q=' + btoa(JSON.stringify(formData.value)));
  try {
    let response = await http.post('/api/v1/report/search', formData.value, {
      headers: { 'Content-Type': 'application/json' }
    });

    console.log(response.data.message);
    results.value = response.data.message.value;
    screenMode.value = 'results';
  } finally {
    formSubmissionLoading.value = false;
  }
});

watch(results, () => {
  let ag = agGrid.value;
  console.log('watched results', agGrid, ag, results, results.value);
  agGridApi.value.setRowData(results.value);
  agGridApi.value.setColumnDefs(columnDefs);
  agGridApi.value.setSideBarVisible(true);
  if (agGridApi.value.isToolPanelShowing()) {
    agGridApi.value.closeToolPanel();
  }
});

onMounted(() => {
  let value = predefinedDateRange.value.value;
  updateDatesToMatchPeriod({ target: { value } });

  setTimeout(() => {
    let searchParams = new URL(window.location.href).searchParams;
    if (searchParams.has('q')) {
      formData.value = JSON.parse(atob(searchParams.get('q')));
    }
  }, 100);
});

function updateDatesToMatchPeriod(e) {
  console.log('updateDatesToMatchPeriod');
  switch (e.target.value) {
    case 'day':
    case 'week':
    case 'month': {
      let now = DateTime.now();
      customDateRangeStartModel.value = now
        .startOf(e.target.value)
        .toFormat('yyyy-MM-dd');
      customDateRangeEndModel.value = now.toFormat('yyyy-MM-dd');
      break;
    }
    case 'last-day':
    case 'last-week':
    case 'last-month': {
      let unit = e.target.value.substring('last-'.length);
      let last = DateTime.now().minus({ [unit]: 1 });
      customDateRangeStartModel.value = last
        .startOf(unit)
        .toFormat('yyyy-MM-dd');
      customDateRangeEndModel.value = last.endOf(unit).toFormat('yyyy-MM-dd');
      break;
    }
  }
}

/**
 * @param {SubmitEvent} $event
 */
function formDataCallback($event) {
  console.log('formDataCallback');
  let rawValues = Object.fromEntries(
    new window.FormData($event.target).entries()
  );
  console.log('inside formDataCallback with rawValues', rawValues);

  let searchParams = {};
  searchParams.customFields = {};

  let checkBoxes = Object.fromEntries(
    Object.entries(rawValues).filter(([k]) => k.endsWith(CHECKBOX_SUFFIX))
  );

  let actualRawValues = Object.fromEntries(
    Object.entries(rawValues).filter(([k]) => !k.endsWith(CHECKBOX_SUFFIX))
  );

  for (let key in actualRawValues) {
    if (checkBoxes[key + CHECKBOX_SUFFIX] !== 'on') continue;
    if (key.startsWith('customField:')) {
      searchParams.customFields[key.substring('customField:'.length)] =
        rawValues[key];
    } else {
      searchParams[key] = rawValues[key];
    }
  }

  let original_customDateRangeStart = searchParams['customDateRangeStart']
  if (original_customDateRangeStart) {
    searchParams['customDateRangeStart'] = fixUpCdrDate(original_customDateRangeStart);
  }
  let original_customDateRangeEnd = searchParams['customDateRangeEnd']
  if (original_customDateRangeEnd) {
    searchParams['customDateRangeEnd'] = fixUpCdrDate(original_customDateRangeEnd);
  }

  formData.value = searchParams;
}

function fixUpCdrDate(original) {
  return new Date(original + 'T00:00:00.000Z').getTime();
}

async function refineSearch() {
  screenMode.value = 'search';
  await router.push('/reporting/search');
}
</script>
<style scoped></style>

<template>
  <span class="title">Enroll</span>
  <div class="clients management-page container">
    <!-- Application Type Tabs -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
            class="nav-link"
            :class="{ active: selectedApplicationType === 'Merchant' }"
            @click.prevent="selectApplicationType('Merchant')"
            href="#"
        >
          Merchant
        </a>
      </li>
      <li class="nav-item">
        <a
            class="nav-link"
            :class="{ active: selectedApplicationType === 'Partner' }"
            @click.prevent="selectApplicationType('Partner')"
            href="#"
        >
          Partner
        </a>
      </li>
    </ul>

    <form id="enrollForm" @submit.prevent="enrollMerchant">
      <div class="row g-3" style="margin-top: 20px">
        <!-- Common Fields -->
        <div class="col-md-6">
          <label class="form-label">
            Business Name (DBA)
            <span :style="{ color: getAsteriskColor('dba_name') }">*</span>
          </label>
          <div class="input-group">
            <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.dba_name && touchedFields.dba_name }"
                v-model="formData.dba_name"
                @blur="onBlur('dba_name')"
                aria-label="Business Name (DBA)"
            />
          </div>
          <div v-if="errors.dba_name && touchedFields.dba_name" class="text-danger">
            {{ errors.dba_name }}
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label">
            Legal Business Name
            <span :style="{ color: getAsteriskColor('legal_name') }">*</span>
          </label>
          <div class="input-group">
            <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.legal_name && touchedFields.legal_name }"
                v-model="formData.legal_name"
                @blur="onBlur('legal_name')"
                aria-label="Legal Business Name"
            />
          </div>
          <div v-if="errors.legal_name && touchedFields.legal_name" class="text-danger">
            {{ errors.legal_name }}
          </div>
        </div>

        <!-- Partner-Specific Fields -->
        <template v-if="selectedApplicationType === 'Partner'">
          <div class="col-md-6">
            <label class="form-label">
              Principal Email
              <span :style="{ color: getAsteriskColor('email') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email && touchedFields.email }"
                  v-model="formData.email"
                  @blur="onBlur('email')"
                  aria-label="Email Address"
              />
            </div>
            <div v-if="errors.email && touchedFields.email" class="text-danger">
              {{ errors.email }}
            </div>
          </div>

          <!-- Select Parent of Company -->
          <div class="col-md-6">
            <label class="form-label">
              Select Parent of Company
              <span :style="{ color: getAsteriskColor('selectedParent') }">*</span>
            </label>
            <div class="input-group">
              <select
                  v-model="selectedParent"
                  class="form-select"
                  :class="{ 'is-invalid': errors.selectedParent && touchedFields.selectedParent }"
                  @change="onBlur('selectedParent')"
              >
                <option value="" disabled>Select a Parent</option>
                <option
                    v-for="option in parentOptions"
                    :key="option.parent_id"
                    :value="option.parent_id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div v-if="errors.selectedParent && touchedFields.selectedParent" class="text-danger">
              {{ errors.selectedParent }}
            </div>
          </div>

          <div class="navbar navbar-dark bg-primary">
            <a class="navbar-brand" href="#">&nbsp;&nbsp;Additional Partner Information</a>
          </div>

          <!-- Federal Tax ID (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Federal Tax ID</label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="formData.fed_tx_id"
                  @input="onFedTaxIdInput"
                  aria-label="Federal Tax ID"
              />
            </div>
            <div v-if="errors.fed_tx_id && touchedFields.fed_tx_id" class="text-danger">
              {{ errors.fed_tx_id }}
            </div>
          </div>

          <!-- Custom Agreement Upload (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Custom Agreement Upload</label>
            <div class="input-group">
              <input
                  type="file"
                  class="form-control"
                  @change="onFileChange"
                  aria-label="Custom Agreement Upload"
                  accept=".pdf"
              />
            </div>
            <div v-if="errors.document && touchedFields.document" class="text-danger">
              {{ errors.document }}
            </div>
          </div>

          <!-- Principal First Name (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Principal First Name</label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="formData.principals[0].first_name"
                  aria-label="Principal First Name"
              />
            </div>
          </div>

          <!-- Principal Last Name (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Principal Last Name</label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="formData.principals[0].last_name"
                  aria-label="Principal Last Name"
              />
            </div>
          </div>

          <!-- Principal Date of Birth (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Principal Date of Birth</label>
            <div class="input-group">
              <input
                  type="date"
                  class="form-control"
                  v-model="formData.principals[0].date_of_birth"
                  aria-label="Principal Date of Birth"
              />
            </div>
          </div>

          <!-- Business Address (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Business Address</label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="formData.business_address_1"
                  aria-label="Business Address"
              />
            </div>
          </div>

          <!-- Business State (Optional) -->
          <div class="col-md-6">
            <label class="form-label">Business State</label>
            <div class="input-group">
              <select
                  class="form-select"
                  v-model="formData.business_state_province"
                  aria-label="Business State"
              >
                <option value="" disabled>Select State</option>
                <option v-for="state in states" :key="state.value" :value="state.value">
                  {{ state.text }}
                </option>
              </select>
            </div>
          </div>

          <!-- Business Zip (Optional with Validation) -->
          <div class="col-md-6">
            <label class="form-label">Business Zip</label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  v-model="formData.business_postal_code"
                  @input="onBusinessZipInput"
                  aria-label="Business Zip"
              />
            </div>
            <div
                v-if="errors.business_postal_code && touchedFields.business_postal_code"
                class="text-danger"
            >
              {{ errors.business_postal_code }}
            </div>
          </div>
        </template>

        <!-- Merchant Form -->
        <template v-if="selectedApplicationType === 'Merchant'">
          <!-- Email Address -->
          <div class="col-md-6">
            <label class="form-label">
              Email Address
              <span :style="{ color: getAsteriskColor('email') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email && touchedFields.email }"
                  v-model="formData.email"
                  @blur="onBlur('email')"
                  aria-label="Email Address"
              />
            </div>
            <div v-if="errors.email && touchedFields.email" class="text-danger">
              {{ errors.email }}
            </div>
          </div>

          <!-- Federal Tax ID -->
          <div class="col-md-6">
            <label class="form-label">
              Federal Tax ID
              <span :style="{ color: getAsteriskColor('fed_tx_id') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.fed_tx_id && touchedFields.fed_tx_id }"
                  v-model="formData.fed_tx_id"
                  @input="onFedTaxIdInput"
                  @blur="onBlur('fed_tx_id')"
                  aria-label="Federal Tax ID"
              />
            </div>
            <div v-if="errors.fed_tx_id && touchedFields.fed_tx_id" class="text-danger">
              {{ errors.fed_tx_id }}
            </div>
          </div>

          <!-- MCC Code -->
          <div class="col-md-6">
            <label class="form-label">
              MCC Code
              <span :style="{ color: getAsteriskColor('selectedMcc') }">*</span>
            </label>
            <div class="input-group">
              <select
                  class="form-select"
                  :class="{ 'is-invalid': errors.selectedMcc && touchedFields.selectedMcc }"
                  v-model="selectedMcc"
                  @change="onBlur('selectedMcc')"
              >
                <option value="" disabled>Select an MCC Code</option>
                <option
                    v-for="option in mccOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div v-if="errors.selectedMcc && touchedFields.selectedMcc" class="text-danger">
              {{ errors.selectedMcc }}
            </div>
          </div>

          <!-- Select Parent of Company -->
          <div class="col-md-6">
            <label class="form-label">
              Select Parent of Company
              <span :style="{ color: getAsteriskColor('selectedParent') }">*</span>
            </label>
            <div class="input-group">
              <select
                  v-model="selectedParent"
                  class="form-select"
                  :class="{ 'is-invalid': errors.selectedParent && touchedFields.selectedParent }"
                  @change="onBlur('selectedParent')"
              >
                <option value="" disabled>Select a Parent</option>
                <option
                    v-for="option in parentOptions"
                    :key="option.parent_id"
                    :value="option.parent_id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div v-if="errors.selectedParent && touchedFields.selectedParent" class="text-danger">
              {{ errors.selectedParent }}
            </div>
          </div>

          <!-- Pricing Information -->
          <div class="navbar navbar-dark bg-primary">
            <a class="navbar-brand" href="#">&nbsp;&nbsp;Pricing Information</a>
          </div>

          <!-- Card Percent Charge -->
          <div class="col-md-6">
            <label class="form-label">
              Card Percent Charge
              <span :style="{ color: getAsteriskColor('cardPercentCharge') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.cardPercentCharge && touchedFields.cardPercentCharge }"
                  v-model="cardPercentCharge"
                  @input="onCardPercentChargeInput"
                  @blur="onBlur('cardPercentCharge')"
                  aria-label="Card Price"
              />
              <span class="input-group-text">%</span>
            </div>
            <div
                v-if="errors.cardPercentCharge && touchedFields.cardPercentCharge"
                class="text-danger"
            >
              {{ errors.cardPercentCharge }}
            </div>
          </div>

          <!-- Card Per Transaction Charge -->
          <div class="col-md-6">
            <label class="form-label">
              Card Per Transaction Charge
              <span :style="{ color: getAsteriskColor('cardPerTransactionCharge') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  :class="{
                  'is-invalid':
                    errors.cardPerTransactionCharge && touchedFields.cardPerTransactionCharge,
                }"
                  v-model="cardPerTransactionCharge"
                  @blur="onBlur('cardPerTransactionCharge')"
                  aria-label="Card Per Transaction Charge"
              />
            </div>
            <div
                v-if="errors.cardPerTransactionCharge && touchedFields.cardPerTransactionCharge"
                class="text-danger"
            >
              {{ errors.cardPerTransactionCharge }}
            </div>
          </div>

          <!-- ACH Percent Charge -->
          <div class="col-md-6">
            <label class="form-label">
              ACH Percent Charge
              <span :style="{ color: getAsteriskColor('achPercentCharge') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.achPercentCharge && touchedFields.achPercentCharge }"
                  v-model="achPercentCharge"
                  @input="onAchPercentChargeInput"
                  @blur="onBlur('achPercentCharge')"
                  aria-label="ACH Price"
              />
              <span class="input-group-text">%</span>
            </div>
            <div
                v-if="errors.achPercentCharge && touchedFields.achPercentCharge"
                class="text-danger"
            >
              {{ errors.achPercentCharge }}
            </div>
          </div>

          <!-- ACH Per Transaction Charge -->
          <div class="col-md-6">
            <label class="form-label">
              ACH Per Transaction Charge
              <span :style="{ color: getAsteriskColor('achPerTransactionCharge') }">*</span>
            </label>
            <div class="input-group">
              <input
                  type="text"
                  class="form-control"
                  :class="{
                  'is-invalid':
                    errors.achPerTransactionCharge && touchedFields.achPerTransactionCharge,
                }"
                  v-model="achPerTransactionCharge"
                  @blur="onBlur('achPerTransactionCharge')"
                  aria-label="ACH Per Transaction Charge"
              />
            </div>
            <div
                v-if="errors.achPerTransactionCharge && touchedFields.achPerTransactionCharge"
                class="text-danger"
            >
              {{ errors.achPerTransactionCharge }}
            </div>
          </div>
        </template>
      </div>

      <!-- Modal Footer -->
      <div class="modal-footer">
        <button
            type="submit"
            class="btn btn-primary"
            :disabled="!isFormValid || isSubmitting"
        >
          Enroll & Send Agreement
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import http from '@/services/http';
import { useToast } from 'vue-toastification';
import {PERMISSION_PRIMITIVES, PRIMARY_CLIENT_ID} from "@/config/constants";

const router = useRouter();
const toast = useToast();

const selectedApplicationType = ref('Merchant');

function selectApplicationType(type) {
  selectedApplicationType.value = type;
  selectedLevel.value = type;
  // Reset form data and errors when switching application types
  resetForm();
}

function resetForm() {
  formData.value = {
    // Business Information
    dba_name: '',
    legal_name: '',
    business_description: '',
    start_date: '',
    ownership_type: '',
    business_phone_number: '',
    email: '',
    website: '',
    avg_ticket: '',
    monthly_volume: '',
    fed_tx_id: '',
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state_province: '',
    business_postal_code: '',
    // Settlement Account Information
    account_number: '',
    routing_number: '',
    account_type: '',
    percent_of_business_type: '',
    // Owner Information
    principals: [
      {
        first_name: '',
        last_name: '',
        ssn: '',
        date_of_birth: '',
        ownership_percentage: '',
        title: '',
        phone_number: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_province: '',
        postal_code: '',
      },
    ],
    document: null,
  };
  errors = reactive({});
  touchedFields = reactive({});
  // Reset other fields
  selectedParent.value = '';
  selectedMcc.value = '';
  selectedLevel.value = '';
  achPercentCharge.value = '';
  achPerTransactionCharge.value = '';
  cardPercentCharge.value = '';
  cardPerTransactionCharge.value = '';
}

const formData = ref({
  // Business Information
  dba_name: '',
  legal_name: '',
  business_description: '',
  start_date: '',
  ownership_type: '',
  business_phone_number: '',
  email: '',
  website: '',
  avg_ticket: '',
  monthly_volume: '',
  fed_tx_id: '',
  business_address_1: '',
  business_address_2: '',
  business_city: '',
  business_state_province: '',
  business_postal_code: '',
  // Settlement Account Information
  account_number: '',
  routing_number: '',
  account_type: '',
  percent_of_business_type: '',
  // Owner Information
  principals: [
    {
      first_name: '',
      last_name: '',
      ssn: '',
      date_of_birth: '',
      ownership_percentage: '',
      title: '',
      phone_number: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state_province: '',
      postal_code: '',
    },
  ],
  document: null,
});

const ownershipTypes = ref([
  'Sole Proprietor',
  'C-Corp Private',
  'C-Corp Public',
  'S-Corp Private',
  'S-Corp Public',
  'LLC Private',
  'LLC Public',
  'Not For Profit',
  'Partnership Private',
  'Partnership',
  'Government Agency',
]);

const states = ref([
  { text: 'Alabama', value: 'AL' },
  { text: 'Alaska', value: 'AK' },
  { text: 'Arizona', value: 'AZ' },
  { text: 'Arkansas', value: 'AR' },
  { text: 'California', value: 'CA' },
  { text: 'Colorado', value: 'CO' },
  { text: 'Connecticut', value: 'CT' },
  { text: 'Delaware', value: 'DE' },
  { text: 'Florida', value: 'FL' },
  { text: 'Georgia', value: 'GA' },
  { text: 'Hawaii', value: 'HI' },
  { text: 'Idaho', value: 'ID' },
  { text: 'Illinois', value: 'IL' },
  { text: 'Indiana', value: 'IN' },
  { text: 'Iowa', value: 'IA' },
  { text: 'Kansas', value: 'KS' },
  { text: 'Kentucky', value: 'KY' },
  { text: 'Louisiana', value: 'LA' },
  { text: 'Maine', value: 'ME' },
  { text: 'Maryland', value: 'MD' },
  { text: 'Massachusetts', value: 'MA' },
  { text: 'Michigan', value: 'MI' },
  { text: 'Minnesota', value: 'MN' },
  { text: 'Mississippi', value: 'MS' },
  { text: 'Missouri', value: 'MO' },
  { text: 'Montana', value: 'MT' },
  { text: 'Nebraska', value: 'NE' },
  { text: 'Nevada', value: 'NV' },
  { text: 'New Hampshire', value: 'NH' },
  { text: 'New Jersey', value: 'NJ' },
  { text: 'New Mexico', value: 'NM' },
  { text: 'New York', value: 'NY' },
  { text: 'North Carolina', value: 'NC' },
  { text: 'North Dakota', value: 'ND' },
  { text: 'Ohio', value: 'OH' },
  { text: 'Oklahoma', value: 'OK' },
  { text: 'Oregon', value: 'OR' },
  { text: 'Pennsylvania', value: 'PA' },
  { text: 'Rhode Island', value: 'RI' },
  { text: 'South Carolina', value: 'SC' },
  { text: 'South Dakota', value: 'SD' },
  { text: 'Tennessee', value: 'TN' },
  { text: 'Texas', value: 'TX' },
  { text: 'Utah', value: 'UT' },
  { text: 'Vermont', value: 'VT' },
  { text: 'Virginia', value: 'VA' },
  { text: 'Washington', value: 'WA' },
  { text: 'West Virginia', value: 'WV' },
  { text: 'Wisconsin', value: 'WI' },
  { text: 'Wyoming', value: 'WY' },
]);

const achPercentCharge = ref('');
const achPerTransactionCharge = ref('');
const cardPercentCharge = ref('');
const cardPerTransactionCharge = ref('');

const currencyMask = '$#0.00'; // Currency mask for input formatting

const selectedLevel = ref('Merchant'); // defaults to merchant because that's where the tab initially lands
const levelOptions = ref(['Merchant', 'Parent']);

const selectedMcc = ref('');
const selectedParent = ref('');

let errors = reactive({});
let touchedFields = reactive({});

const requiredFields = computed(() => {
  if (selectedApplicationType.value === 'Merchant') {
    return [
      // Required fields for Merchant
      'email',
      'dba_name',
      'legal_name',
      'fed_tx_id',
      'selectedMcc',
      'selectedParent',
      'selectedLevel',
      'cardPercentCharge',
      'cardPerTransactionCharge',
      'achPercentCharge',
      'achPerTransactionCharge',
    ];
  } else {
    // Required fields for Partner
    return [
      'email',
      'dba_name',
      'legal_name',
      'selectedParent',
    ];
  }
});

function getAsteriskColor(fieldName) {
  if (touchedFields[fieldName]) {
    return errors[fieldName] ? 'red' : 'green';
  }
  return 'red'; // Default to red if not touched
}

function validateForm() {
  // Clear previous errors
  errors = reactive({});

  // Common Validations (applies to both Merchant and Partner)
  if (!formData.value.dba_name) {
    errors.dba_name = "Business Name (DBA) is required.";
  }

  if (!formData.value.legal_name) {
    errors.legal_name = "Legal Business Name is required.";
  }

  if (!formData.value.email) {
    errors.email = "Email is required.";
  } else if (!isValidEmail(formData.value.email)) {
    errors.email = "Invalid email address.";
  }

  if (!selectedParent.value) {
    errors.selectedParent = "Parent is required.";
  }

  // Partner-Specific Validations
  if (selectedApplicationType.value === 'Partner') {
    // Business Zip validation (optional but if entered, must be valid)
    if (formData.value.business_postal_code) {
      const zip = formData.value.business_postal_code;
      if (!/^\d{5}(-\d{4})?$/.test(zip)) {
        errors.business_postal_code = "Invalid ZIP code format.";
      }
    }

    // Validate that the uploaded document is a PDF if provided
    if (formData.value.document) {
      if (formData.value.document.extension.toLowerCase() !== 'pdf') {
        errors.document = "Only PDF documents are allowed.";
      }
    }
  }

  // Merchant-Specific Validations
  if (selectedApplicationType.value === 'Merchant') {
    // Validate Federal Tax ID
    if (!formData.value.fed_tx_id) {
      errors.fed_tx_id = "Federal Tax ID is required.";
    } else if (formData.value.fed_tx_id.replace(/\D/g, '').length !== 9) {
      errors.fed_tx_id = "Federal Tax ID must be 9 digits.";
    }

    // Validate MCC Code
    if (!selectedMcc.value) {
      errors.selectedMcc = "MCC Code is required.";
    }

    // Validate Company Level
    if (!selectedLevel.value) {
      errors.selectedLevel = "Company Level is required.";
    }

    // Validate Card Percent Charge
    if (!cardPercentCharge.value) {
      errors.cardPercentCharge = "Card Percent Charge is required.";
    } else if (!isValidPercent(cardPercentCharge.value, 2, 10)) {
      errors.cardPercentCharge =
          "The rate provided is outside the boundaries of our norms. Please contact us to discuss further.";
    }

    // Validate Card Per Transaction Charge
    if (!cardPerTransactionCharge.value) {
      errors.cardPerTransactionCharge = "Card Per Transaction Charge is required.";
    } else if (!isValidPerTransactionCharge(cardPerTransactionCharge.value)) {
      errors.cardPerTransactionCharge = "Value must be between $0.00 and $5.00.";
    }

    // Validate ACH Percent Charge
    if (!achPercentCharge.value) {
      errors.achPercentCharge = "ACH Percent Charge is required.";
    } else if (!isValidPercent(achPercentCharge.value, 2, 10)) {
      errors.achPercentCharge =
          "The rate provided is outside the boundaries of our norms. Please contact us to discuss further.";
    }

    // Validate ACH Per Transaction Charge
    if (!achPerTransactionCharge.value) {
      errors.achPerTransactionCharge = "ACH Per Transaction Charge is required.";
    } else if (!isValidPerTransactionCharge(achPerTransactionCharge.value)) {
      errors.achPerTransactionCharge = "Value must be between $0.00 and $5.00.";
    }
  }
}

function onBlur(fieldName) {
  touchedFields[fieldName] = true;
  validateForm();
}

function isValidEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function isValidPercent(value, min, max) {
  const num = parseFloat(value);
  return !isNaN(num) && num >= min && num <= max;
}

function isValidPerTransactionCharge(value) {
  const num = parseFloat(value.replace(/[^0-9.]/g, ''));
  return !isNaN(num) && num >= 0 && num <= 5;
}

function onFedTaxIdInput() {
  let value = formData.value.fed_tx_id.replace(/\D/g, '');

  if (value.length > 2) {
    value = value.slice(0, 2) + '-' + value.slice(2);
  }
  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  formData.value.fed_tx_id = value;
}

function onBusinessZipInput() {
  formData.value.business_postal_code = formData.value.business_postal_code.replace(
      /[^0-9-]/g,
      ''
  );
  touchedFields.business_postal_code = true;
  validateForm();
}

function onFileChange(event) {
  const file = event.target.files[0];
  touchedFields.document = true;

  if (file) {
    const extension = file.name.split('.').pop().toLowerCase();
    if (extension !== 'pdf') {
      errors.document = "Only PDF documents are allowed.";
      formData.value.document = null;
    } else {
      errors.document = null;
      const reader = new FileReader();
      reader.onload = function(e) {
        const base64Content = e.target.result.split(',')[1]; // Remove the data URL prefix
        formData.value.document = {
          name: file.name,
          extension: extension,
          content: base64Content,
        };
      };
      reader.readAsDataURL(file);
    }
  } else {
    formData.value.document = null;
  }
  validateForm();
}

function onCardPercentChargeInput() {
  let value = cardPercentCharge.value.replace(/[^0-9.]/g, '');
  if (value.includes('.')) {
    const [integerPart, decimalPart] = value.split('.');
    value = integerPart + '.' + decimalPart.slice(0, 2);
  }
  cardPercentCharge.value = value;
  validateForm();
}

function onAchPercentChargeInput() {
  let value = achPercentCharge.value.replace(/[^0-9.]/g, '');
  if (value.includes('.')) {
    const [integerPart, decimalPart] = value.split('.');
    value = integerPart + '.' + decimalPart.slice(0, 2);
  }
  achPercentCharge.value = value;
  validateForm();
}

function onCurrencyInput(fieldName) {
  let value = '';

  if (fieldName === 'cardPerTransactionCharge') {
    value = cardPerTransactionCharge.value;
  } else if (fieldName === 'achPerTransactionCharge') {
    value = achPerTransactionCharge.value;
  }

  // Remove dollar sign and any non-numeric characters except decimal point
  let numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));

  // If numericValue is greater than 5.00, set it to 5.00
  if (numericValue > 5.0) {
    numericValue = 5.0;
  }

  // If numericValue is NaN, set it to 0
  if (isNaN(numericValue)) {
    numericValue = 0;
  }

  // // Format numericValue to two decimal places
  // const formattedValue = '$' + numericValue.toFixed(2);

  // Update the field value
  if (fieldName === 'cardPerTransactionCharge') {
    cardPerTransactionCharge.value = numericValue;
  } else if (fieldName === 'achPerTransactionCharge') {
    achPerTransactionCharge.value = numericValue;
  }

  // Validate the form
  validateForm();
}

const isFormValid = computed(() => {
  validateForm();
  return Object.keys(errors).length === 0 && !isSubmitting.value;
});

// Enroll merchant or partner
const isSubmitting = ref(false);
const enrollMerchant = () => {
  validateForm();
  if (Object.keys(errors).length > 0) {
    // There are validation errors, do not proceed
    toast.error('Please fix the errors in the form before submitting.');
    return;
  }

  isSubmitting.value = true;

  const enrollmentData = {
    ...formData.value,
    parent: selectedParent.value,
    is_from_portal: true,
    application_type: selectedApplicationType.value,
  };

  // For merchant application, include additional data
  if (selectedApplicationType.value === 'Merchant') {
    // Remove dollar sign before submitting
    const achPerTransactionChargeValue = achPerTransactionCharge.value.replace('$', '');
    const cardPerTransactionChargeValue = cardPerTransactionCharge.value.replace('$', '');

    if (!achPercentCharge.value) {
      achPercentCharge.value = '0';
    }
    if (!achPerTransactionChargeValue) {
      achPerTransactionCharge.value = '0';
    }
    const achFee = `${achPercentCharge.value}+${achPerTransactionChargeValue}`;

    if (!cardPercentCharge.value) {
      cardPercentCharge.value = '0';
    }
    if (!cardPerTransactionChargeValue) {
      cardPerTransactionCharge.value = '0';
    }
    const cardRate = `${cardPercentCharge.value}+${cardPerTransactionChargeValue}`;

    enrollmentData.monthly_volume = enrollmentData.monthly_volume.replace(/,/g, '');
    enrollmentData.mcc_code = selectedMcc.value;
    enrollmentData.is_from_portal = true;
    enrollmentData.ach_rate = achFee;
    enrollmentData.card_rate = cardRate;
    enrollmentData.level = selectedLevel.value;
  }

  // Remove null values to clean up the data
  Object.keys(enrollmentData).forEach((key) => {
    if (enrollmentData[key] === null || enrollmentData[key] === '') {
      delete enrollmentData[key];
    }
  });

  http
      .post('/api/enroll', enrollmentData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        isSubmitting.value = false;
        toast.success(
            'Success!'
        );
        // Reload the component instead of resetting the form
        router.go(0);
      })
      .catch((error) => {
        isSubmitting.value = false;
        toast.error(
            'Enrollment Failed - Please Retry Or Contact Us csm@tripleplaypay.com'
        );
      });
};

const mccOptions = ref([]);
const parentOptions = ref([]);
onMounted(() => {
  http
      .get('/api/approved-mcc-codes')
      .then((response) => {
        const data = response.data?.message || {};
        mccOptions.value = Object.keys(data).map((key) => {
          return {
            value: key,
            label: `${data[key]} #${key}`,
          };
        });

        mccOptions.value.sort((a, b) => a.label.localeCompare(b.label));
      })
      .catch((error) => {
        console.error('Error fetching MCC codes:', error);
      });

  http
      .get('/api/parent-assignment')
      .then((response) => {
        parentOptions.value = response.data?.message || [];
      })
      .catch((error) => {
        console.error('Error fetching Parent Data:', error);
      });
});

const store = useStore();
const defaultClientid = store.getters.defaultClientid;

const userHasPermission = computed(
    () =>
        !store.getters.merchantHasPermissions ||
        store.getters.grantedPermissions.includes(
            PERMISSION_PRIMITIVES.PERMISSIONS
        )
);

if (!userHasPermission.value && defaultClientid !== PRIMARY_CLIENT_ID) {
  toast.error('User does not have MIMIC permission.');
  router.push('/');
}
</script>

<style lang="scss" scoped>
.title {
  color: #383838;
  font-size: 24px;
}
.loader {
  z-index: 2;
}

.add-user {
  height: 40px;
  padding: 0px 30px;
}

.search-dropdown {
  min-width: 200px;
}

.settings-btn {
  height: 40px;
  padding-top: 7px !important;
  border-radius: 5px !important;

  span {
    vertical-align: middle;
  }
}

.settings-link {
  background-color: var(--c-primary);
  color: var(--c-white);
  border-radius: 5px;
  text-decoration: none;
  height: 40px;
  padding: 7px 12px 6px;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7a540;
  }
}

.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
.modal-header {
  background-color: #6C757D;
  color: white;
  border-bottom: none;
}

.modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
}

.modal-body {
  padding: 20px;
}

.modal-body h6 {
  font-size: 18px;
  font-weight: 500;
}

.modal-body p a {
  color: black;
  text-decoration: underline;
}

.modal-body .form-group label {
  font-weight: 600;
}
.is-invalid {
  border-color: #dc3545;
}
.text-danger {
  color: #dc3545;
}
</style>

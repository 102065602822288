<template>
  <div class="mb-3">
    <span class="dashboard-title">Dashboard</span>
  </div>

  <div class="dashboard-content mt-4">
    <!-- Virtual Terminal -->
    <div v-if="virtualTerminalPermissions" class="virtual-terminal mt-4">
      <h2 class="section-title">My Virtual Terminal</h2>
      <div class="row gap-4">
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center">
          <button
            :disabled="!defaultApiKey"
            type="button"
            class="btn btn-dark"
            @click="
              () => {
                router.push(`/virtual-terminal/${defaultApiKey}`);
              }
            "
          >
            Fast Terminal
          </button>
        </div>
        <div class="col-12 col-md-3 metric-cards d-flex justify-content-center">
          <button
            type="button"
            :disabled="!defaultApiKey"
            class="btn btn-outline-dark"
            @click="
              () => {
                router.push(`/configure-terminal/${defaultApiKey}`);
              }
            "
          >
            Configure
          </button>
        </div>
      </div>
    </div>
    <div class="transaction-tools">
      <h2 class="section-title">Tools</h2>
      <div class="row gap-4">
        <!-- Virtual Terminal Links -->
        <div
          v-if="virtualTerminalPermissions"
          class="col-12 col-md-4 metric-cards"
        >
          <h5 class="card-title">Open a Virtual Terminal</h5>
          <ul class="vt-link-list p-0 list-group-flush">
            <li
              v-for="(merchant, index) in userMerchants"
              :key="index"
              class="list-group-item"
            >
              <router-link
                :to="`/virtual-terminal/${merchant.apikey}`"
                class="text-primary"
                style="text-decoration: none"
              >
                {{ merchant.name }} Virtual Terminal
              </router-link>
            </li>
          </ul>
        </div>

        <div
            v-if="virtualTerminalPermissions"
            class="col-12 col-md-4 metric-cards"
        >
          <h5 class="card-title">Configure a Virtual Terminal</h5>
          <ul class="vt-link-list p-0 list-group-flush">
            <li
                v-for="(merchant, index) in userMerchants"
                :key="index"
                class="list-group-item"
            >
              <router-link
                  :to="`/configure-terminal/${merchant.apikey}`"
                  class="text-primary"
                  style="text-decoration: none"
              >
                {{ merchant.name }} Virtual Terminal
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="checkForParentAccountUser()" class="col-12 col-md-7 metric-cards">
          <router-link
              :to="`/enroll-portal-page`"
              class="text-primary"
              style="text-decoration: none"
          >
            Create New Enrollment
          </router-link>
        </div>
      </div>
    </div>
    <!-- Metric Cards -->
    <div class="metric-cards">
      <h5>Transaction Metrics</h5>
      <div class="row">
        <div v-if="role === 'admin'" class="form-floating mb-2 ms-2">
          <select
            id="merchantDropdown"
            v-model="selectedMerchantId"
            class="form-select"
            style="width: 300px"
          >
            <option :value="null" disabled>Select an Account</option>
            <option
              v-for="merchant in merchants"
              :key="merchant.id"
              :value="merchant.id"
            >
              {{ merchant.name }}
            </option>
          </select>
          <label for="merchantDropdown" class="ms-2">Merchant:</label>
        </div>
        <div class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 140px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Approved Transactions
            </p>
            <hr class="m-0 p-0" />
            <div v-if="statsLoading">
              <BaseSpinner />
            </div>
            <div v-else>
              <div class="metric-sub">Last 7 Days ({{ last7DateRange }})</div>
              <div class="m-0 metric-sub-data">{{ transLast7 }}</div>
              <div class="metric-sub">Last 30 Days ({{ last30DateRange }})</div>
              <div class="m-0 metric-sub-data">{{ transLast30 }}</div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 140px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Total Volume
            </p>
            <hr class="m-0 p-0" />
            <div v-if="statsLoading">
              <BaseSpinner />
            </div>
            <div v-else>
              <div class="metric-sub">Last 7 Days ({{ last7DateRange }})</div>
              <div class="m-0 metric-sub-data">${{ volLast7 }}</div>
              <div class="metric-sub">Last 30 Days ({{ last30DateRange }})</div>
              <div class="m-0 metric-sub-data">${{ volLast30 }}</div>
            </div>
          </div>
        </div>

        <div class="col-12 col-xxl-8">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="min-height: 350px"
          >
            <div v-if="statsLoading">
              <p style="font-weight: bold; text-align: left; font-size: 14px">
                Monthly Volume Data
              </p>
              <BaseSpinner />
            </div>
            <MonthlyTotalsChart v-else :monthly-volume="monthlyVolumeData" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="role === 'admin' || role === 'parent'" class="metric-cards">
      <h5>Operations Metrics</h5>
      <div class="row">
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Open Risk Alerts
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalRisk }}</div>
          </div>
        </div>
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Open Ops Alerts
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalOps }}</div>
          </div>
        </div>
        <div v-if="role === 'admin'" class="col-12 col-md-6 col-xxl-3">
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Parents
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalParents }}</div>
          </div>
        </div>
        <div
          v-if="role === 'admin' || role === 'parent'"
          class="col-12 col-md-6 col-xxl-3"
        >
          <div
            class="card shadow-sm p-2 d-flex flex-column"
            style="height: 100px"
          >
            <p style="font-weight: bold; text-align: left; font-size: 14px">
              Merchants
            </p>
            <hr class="m-0 p-0" />
            <div class="metric-sub-data">{{ totalMerchants }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import http from '@/services/http';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { useStore } from 'vuex';
import { processMonthlyVolume } from '@/helpers';
import MonthlyTotalsChart from '@/components/reporting/MonthlyTotalsChart.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { router } from '@/router';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import BaseModal from "@/components/base/BaseModal.vue";
import { useToast } from 'vue-toastification';
import BaseButton from "@/components/base/BaseButton.vue";

const achPercentCharge = ref("");
const achPerTransactionCharge = ref("");

const cardPercentCharge = ref("");
const cardPerTransactionCharge = ref("");

// Get user data
const toast = useToast();
let toastdefault = { position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false}
const store = useStore();
const defaultClientName = computed(() => store.getters.defaultClientid);
const defaultClientId = computed(() => store.getters.defaultClientId);
/*
const user = computed(() => store.state.user);
*/
const userHasVirtualTerminalPermission = computed(() => {
  return store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL);
});
/*
const permissions = computed(() => store.state.permissions);
*/
const hasPermissions = computed(() => store.getters.merchantHasPermissions);

/**
 * Marks if the user has permissions to use the virtual terminal.
 * True if merchant has no permissions configured or
 * if the user has the virtual terminal permission.
 */
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const role = computed(() => store.getters.role);

const userMerchants = ref([]);

const defaultApiKey = computed(() => {
  console.log(
    'Fetching default API key...',
    userMerchants.value,
    defaultClientId.value
  );
  const defaultMerchant = userMerchants.value.find(
    (merchant) => merchant.id === defaultClientId.value
  );
  if (defaultMerchant) {
    console.log(`Default API key found: ${defaultMerchant.apikey}`);
    return defaultMerchant.apikey;
  } else {
    console.log('No default API key found.');
    return '';
  }
});
console.log('Default Client Name:', defaultClientName.value);
console.log('Default Client ID:', defaultClientId.value);
console.log('Role:', role.value);
console.log('Default API Key:', defaultApiKey.value);

// Fetch api keys attached to merchant for virtual terminals
const fetchUserMerchants = () => {
  http
    .get('/api/user/apikeys')
    .then((response) => {
      // test for empty list
      const merchantData = response.data.message;

      if (merchantData) {
        userMerchants.value = merchantData
          .map((merchant) => ({
            id: merchant.id,
            name: merchant.name,
            apikey: merchant.apikey
          }))
          .sort((a, b) =>
            a.name && b.name ? a.name.localeCompare(b.name) : 0
          );
      }
    })
    .catch((error) => {
      console.error('Error fetching user merchant data:', error);
    });
};

fetchUserMerchants();

// Create list of MCC codes
const selectedMcc = ref('');
const mccCodes = ref([]);

// Fetch MCC codes
// http.get('/api/client?id=' + defaultClientid.value).then((response) => {
//   const data = response.data?.message || response.data || [];
//   if (data) {
//     const subpricing = data.subpricing || [];
//     mccCodes.value = subpricing.map((item) => item.mccCode);
//   }
// });

const enrollName = ref('');
const enrollPhone = ref('');
const enrollEmail = ref('');
const showEnrollModal = ref(false);

const states = ref([
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
]);

// Enroll merchant
const enrollMerchant = () => {
  const enrollmentUrl = `/api/enroll`;
  const enrollmentData = {
    ...formData.value, mcc_code: selectedMcc.value, is_from_portal: true
  }
  console.log(enrollmentData)
  console.log('enrollmentData is above')
  http
    .post(
        enrollmentUrl,
        {
          ...formData.value, mcc_code: selectedMcc.value
        },
        {
          'Content-Type': 'application/json'
        }
    )
    .then((response) => {
      console.log('Enrollment successful', response);
      toast.success('Enrollment Succeeded! Please be in touch with your merchant on finishing their Click-To-Agree Form', toastdefault);
      resetEnrollForm();
    })
    .catch((error) => {
      console.error('Enrollment failed', error);
      toast.error('Enrollment Failed - Please Retry Or Contact Us csm@tripleplaypay.com', toastdefault);
    });
};

// For Enroll and send CTA Button
const enrollAndSendCTA = () => {
  enrollMerchant();
};

const resetEnrollForm = () => {
  formData.value = {
    // Business Information
    dba_name: '',
    legal_name: '',
    business_description: '',
    start_date: '',
    ownership_type: '',
    business_phone_number: '',
    email: '',
    website: '',
    avg_ticket: '',
    monthly_volume: '',
    fed_tx_id: '',
    business_address_1: '',
    business_address_2: '',
    business_city: '',
    business_state_province: '',
    business_postal_code: '',
    // Settlement Account Information
    account_number: '',
    routing_number: '',
    account_type: '',
    percent_of_business_type: '',
    // Owner Information
    principals: [
      {
        first_name: '',
        last_name: '',
        ssn: '',
        date_of_birth: '',
        ownership_percentage: '',
        title: '',
        phone_number: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_province: '',
        postal_code: '',
      },
    ],
  };
  selectedMcc.value = '';
};
const merchantNamesAndIds = computed(() => {
  return store.state.user?.account?.default_client?.merchant?.name;
});

/*
const showTools = computed(() => {
  return checkForTriplePlayPay() || checkForStrata();
});

const checkForTriplePlayPay = () => {
  for (const clientId in merchantNamesAndIds.value) {
    const clientNames = merchantNamesAndIds[clientId];

    if (Array.isArray(clientNames)) {
      if (clientNames.includes('TriplePlayPay')) {
        return true;
      }
    } else if (
      typeof clientNames === 'string' &&
      clientNames === 'TriplePlayPay'
    ) {
      return true;
    }
  }
  return false;
};
*/

const isParentAccountUser = ref(false);

const checkForParentAccountUser = async () => {
  let isParentAccount = false;
  const merchantId = store.state.user?.account?.default_client?.merchant?.id;
  if (merchantId) {
    const apiUrl = `/api/is-parent-merchant/${merchantId}`;

    // Check for the card
    const response = await http.get(apiUrl);
      const merchantLevel = response?.data?.level;
      if (merchantLevel && typeof value === 'string' && merchantLevel.toLowerCase() === 'Parent') {
        isParentAccount = true;
      }
    }

  return isParentAccount;
}

const openEnrollModal = () => {
  showEnrollModal.value = true
}

const formData = ref({
  // Business Information
  dba_name: '',
  legal_name: '',
  business_description: '',
  start_date: '',
  ownership_type: '',
  business_phone_number: '',
  email: '',
  website: '',
  avg_ticket: '',
  monthly_volume: '',
  fed_tx_id: '',
  business_address_1: '',
  business_address_2: '',
  business_city: '',
  business_state_province: '',
  business_postal_code: '',
  // Settlement Account Information
  account_number: '',
  routing_number: '',
  account_type: '',
  percent_of_business_type: '',
  // Owner Information
  principals: [
    {
      first_name: '',
      last_name: '',
      ssn: '',
      date_of_birth: '',
      ownership_percentage: '',
      title: '',
      phone_number: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state_province: '',
      postal_code: '',
    },
  ],
});

// Options for select fields
const ownershipTypes = ref([
  'Sole Proprietor',
  'C-Corp Private',
  'C-Corp Public',
  'S-Corp Private',
  'S-Corp Public',
  'LLC Private',
  'LLC Public',
  'Not For Profit',
  'Partnership Private',
  'Partnership',
  'Government Agency',
]);

const accountTypes = ref([
  { value: 'checking', label: 'Corporate Checking' },
  { value: 'savings', label: 'Corporate Savings' },
]);

const percentOfBusinessTypes = ref([
  { value: 'ecommerce', label: 'E-Commerce (100% internet transactions)' },
  { value: 'swiped', label: 'Swiped (80% card swipe, 20% keyed transactions)' },
  { value: 'moto', label: 'MOTO (20% card swipe, 80% keyed transactions)' },
]);

const principalTitles = ref([
  'CEO',
  'Manager',
  'Owner',
  'Partner',
  'President',
  'Vice President',
]);

// Function to add a new principal
const addPrincipal = () => {
  formData.value.principals.push({
    first_name: '',
    last_name: '',
    ssn: '',
    date_of_birth: '',
    ownership_percentage: '',
    title: '',
    phone_number: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state_province: '',
    postal_code: '',
  });
};

const checkForStrata = () => {
  for (const clientId in merchantNamesAndIds) {
    const clientNames = merchantNamesAndIds[clientId];

    if (Array.isArray(clientNames)) {
      if (
        clientNames.includes('Strata') ||
        clientNames.includes('Nashville NeuroCare Therapy') ||
        clientNames.includes('W Scott West MD PLLC')
      ) {
        return true;
      }
    } else if (
      typeof clientNames === 'string' &&
      (clientNames === 'Strata' ||
        clientNames === 'Nashville NeuroCare Therapy' ||
        clientNames === 'W Scott West MD PLLC')
    ) {
      return true;
    }
  }
  return false;
};

// Charge a tokenized payment method
const searchedEmail = ref('');
const searchedDigits = ref('');
const chargeAmount = ref(0);
const foundCard = ref('');

const searchCards = () => {
  // Make the API call to search for cards based on the entered email
  const apiUrl = `/api/wallet?id=${searchedEmail.value}`;

  // Check for the card
  http
    .get(apiUrl)
    .then((response) => {
      const data = response.data;

      if (data.status && data.message && data.message.wallet) {
        // Check if any of the items in "wallet" contain the searched digits
        foundCard.value = data.message.wallet.find((item) =>
          item.includes(searchedDigits.value)
        );

        if (foundCard.value) {
          console.log('Card found, you can proceed to charge it.');
        } else {
          console.log('No matching card found.');
          alert(
            'Your search did not match any payment methods in our records.'
          );
        }
      } else {
        console.log(
          'No cards found or API response is not as expected.',
          response
        );
        alert(
          'Your search did not match any payment methods in our records or there was another issue. Please try again.'
        );
      }
    })
    .catch((error) => {
      console.error('Error searching for cards:', error);
    });
};

const chargeCard = () => {
  // Ensure charge amount is valid and positive
  if (chargeAmount.value <= 0) {
    console.log('Invalid charge amount.');
    return;
  }

  http
    .post(`/api/charge?amount=${chargeAmount.value}&token=${foundCard.value}`)
    .then((response) => {
      console.log('Charge API Response:', response.data);
      if (response.data.status === true) {
        chargeAmount.value = '';
        alert('Payment was successful.');
      } else {
        console.error('Charge API returned a non-successful status.');
        alert('Payment failed.');
      }
    })
    .catch((error) => {
      console.error('Error charging the card:', error);
      alert('Payment error. Please try again.');
    });
};

/*
// Open Masquerade Mode
const openMasqueradeMode = () => {
  window.location.href = window.location.origin + '/admin/mimic';
};
*/

const selectedMerchantId = ref(null);

const selectedMerchantName = ref(null);

const totalParents = ref(null);
const totalMerchants = ref(null);

const merchants = ref([]);

const handleMerchantChange = () => {
  // Update selectedMerchantName when the user selects a merchant
  const selectedMerchant = merchants.value.find(
    (merchant) => merchant.id === selectedMerchantId.value
  );
  console.log('Selected merchant:', selectedMerchant);
  if (selectedMerchant) {
    selectedMerchantName.value = selectedMerchant.name;
    console.log('selectedMerchantName updated:', selectedMerchantName.value);
  }

  accountTotals();

  riskTotal();

  opsTotal();

  transactionTotals();
};

watchEffect(() => {
  if (selectedMerchantId.value) {
    handleMerchantChange();
  }
});

const loadMerchants = async () => {
  try {
    const response = await http.get(
      `/api/clients?clientid=${defaultClientId.value}`
    );
    if (response.data.status) {
      console.log(response.data);
      console.log('response.data overhead just recieved in LOAD MERCHANTS');
      const unsortedMerchants = response.data.message.map((merchant) => ({
        id: merchant[0],
        name: merchant[1]
      }));

      // Sort the merchants array alphabetically by name
      merchants.value = unsortedMerchants
        .slice()
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    }
  } catch (error) {
    console.error('Error loading merchants:', error);
  } finally {
    if (selectedMerchantId.value == null && defaultClientId.value) {
      selectedMerchantId.value = defaultClientId.value;
    }
  }
};

const mccOptions = ref([]);

onMounted(() => {
  loadMerchants();
  // http.get('/api/approved-mcc-codes')
  //     .then((response) => {
  //       const data = response.data?.message || {};
  //       mccOptions.value = Object.keys(data).map((key) => {
  //         return {
  //           value: key,  // MCC code
  //           label: `${data[key]} #${key}` // description and MCC code
  //         };
  //       });
  //
  //       mccOptions.value.sort((a, b) => a.label.localeCompare(b.label));
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching MCC codes:', error);
  //     });
});

watch(
  [() => selectedMerchantId.value, () => defaultClientId.value],
  ([newMerchantId, newClientId], [oldMerchantId, oldClientId]) => {
    // on first load, handles null merchant ID to update on incoming client ID
    // and refresh data

    if (newMerchantId != null && oldMerchantId == null && newClientId) {
      selectedMerchantId.value = newClientId;

      handleMerchantChange();
    }
  }
);

const transLast7 = ref(null);
const transLast30 = ref(null);
const volLast7 = ref(null);
const volLast30 = ref(null);
const last7DateRange = ref('');
const last30DateRange = ref('');
const monthlyVolumeData = ref([]);
const statsLoading = ref(true);

const totalRisk = ref(null);
const totalOps = ref(null);

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1); // Subtract 1 to get yesterday's date

const eightDaysAgo = new Date(yesterday);
eightDaysAgo.setDate(yesterday.getDate() - 7);
const thirtyOneDaysAgo = new Date(yesterday);
thirtyOneDaysAgo.setDate(yesterday.getDate() - 30);

const dateRanges = () => {
  last7DateRange.value = `${eightDaysAgo.toLocaleDateString()} to ${yesterday.toLocaleDateString()}`;
  last30DateRange.value = `${thirtyOneDaysAgo.toLocaleDateString()} to ${yesterday.toLocaleDateString()}`;
};

dateRanges();

// Dashboard totals/cards
const transactionTotals = () => {
  statsLoading.value = true;
  http
    .get(`/api/stats?id=${selectedMerchantId.value}`)
    .then((response) => {
      const { last7, last30, monthlyVolume } = response.data.message;

      // Initialize variables to store the totals for last 7 and last 30 days
      let transTotalLast7 = 0;
      let volTotalLast7 = 0;
      let transTotalLast30 = 0;
      let volTotalLast30 = 0;
      let monthlyChartData = [];
      // Calculate totals for last 7 days
      if (last7) {
        for (const entry of last7) {
          transTotalLast7 += parseFloat(entry.transactions) || 0;
          volTotalLast7 += parseFloat(entry.total) || 0;
        }
      }

      // Calculate totals for last 30 days
      if (last30) {
        for (const entry of last30) {
          transTotalLast30 += parseFloat(entry.transactions) || 0;
          volTotalLast30 += parseFloat(entry.total) || 0;
        }
      }

      if (monthlyVolume) {
        monthlyChartData = processMonthlyVolume(monthlyVolume);
        monthlyVolumeData.value = monthlyChartData;
        console.log('monthlyChartData PROCESSED', monthlyChartData);
      }

      // Assign the calculated totals to the ref objects
      transLast7.value = transTotalLast7.toString();
      volLast7.value = volTotalLast7.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      transLast30.value = transTotalLast30.toString();
      volLast30.value = volTotalLast30.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    })
    .finally(() => {
      statsLoading.value = false;
    });
};

transactionTotals();

const accountTotals = () => {
  // Make the API call to fetch client metadata
  http
    .get(`/api/portal/clients-meta?id=${selectedMerchantId.value}`)
    .then((response) => {
      const data = response.data.message; // Assuming data is an array of objects

      // Initialize counters for Parent and Merchant
      let parentCount = 0;
      let merchantCount = 0;

      // Loop through the data and count based on the 'level' property
      data.forEach((client) => {
        if (client.level === 'Parent') {
          parentCount++;
        } else if (client.level === 'Merchant') {
          merchantCount++;
        }
      });

      // Assign counts to the ref variables
      totalParents.value = parentCount;
      totalMerchants.value = merchantCount;
    })
    .catch((error) => {
      console.error('Error fetching client metadata:', error);
    });
};

accountTotals();

const riskTotal = () => {
  http
    .get(`/api/risk?id=${selectedMerchantId.value}`)
    .then((response) => {
      if (Array.isArray(response.data.message)) {
        totalRisk.value = response.data.message.length;
      }
    })
    .catch((error) => {
      console.error('Error fetching risk data:', error);
    });
};

riskTotal();

const opsTotal = () => {
  http
    .get(`/api/operations?id=${selectedMerchantId.value}`)
    .then((response) => {
      if (Array.isArray(response.data)) {
        totalOps.value = response.data.message.length;
      }
    })
    .catch((error) => {
      console.error('Error fetching risk data:', error);
    });
};

opsTotal();
</script>

<style scoped>
.dashboard-title {
  font-size: 24px;
  color: #383838;
  margin-bottom: 10px;
}

.dashboard-content {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.section-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.metric-cards {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.metric-sub {
  font-weight: bold;
  color: gray;
  font-size: 12px;
}

.metric-sub-data {
  font-weight: lighter;
  color: gray;
  font-size: 20px;
}

.tools,
.vt-links,
.enrollment-form,
.transaction-tools {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.virtual-terminal {
  padding: 20px;
  border-radius: 8px;
}
.enrollment-form {
  background: #f8f9fa;
}

.buttons {
  display: flex;
  gap: 10px;
}

.btn {
  /* min-width: 200px; */
  margin-top: 10px;
}
</style>

<template>
  <div>
    <div class="mb-3 ms-auto me-auto">
      <span class="dashboard-title">Vault</span>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="form-group">
          <label for="searchParameter">Enter Search Parameter:</label>
          <input id="searchParameter" v-model="searchParameter" type="text" class="form-control" required>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <base-button class="ms-auto search-payee-email" @click="runSearch">
            <span class="fz-16 fw-500 vertical-align-middle"> + </span>
            <span class="vertical-align-middle">Run Search</span>
          </base-button>
        </div>
      </div>
    </div>
    <div class="customer-table">
      <BaseSpinner v-if="customersLoading" />
      <table v-else class="table">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Email</th>
            <th>Merchant</th>
            <th>Payment Methods</th>
            <th colspan="4">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in customers" :key="customer.id">
            <td>
              {{ customer.first_name || '-' }} {{ customer.last_name || '-' }}
            </td>
            <td>{{ customer.email || '-' }}</td>
            <td>{{ customer.merchant_name || '-' }}</td>
            <td>
              <CardTableItem
                v-if="customer?.payment_methods?.length"
                :key="getFirstPaymentMethod(customer)?.id"
                :card-issuer="getFirstPaymentMethod(customer)?.cardType"
                :last-four-digits="getFirstPaymentMethod(customer)?.last4"
                :expiration-date="
                  getFirstPaymentMethod(customer)?.expirationDate
                "
              />
              <span v-if="customer?.payment_methods?.length">
                {{ customer.payment_methods.length }} methods saved
              </span>
              <span v-else> No payment methods saved </span>
            </td>
            <td v-if="virtualTerminalPermissions">
              <button
                  v-if="customer?.has_customer"
                :disabled="!userMerchantsByMerchant[customer.client.id]"
                type="button"
                class="btn btn-dark"
                @click="
              () => {
                router.push(`/virtual-terminal/${userMerchantsByMerchant[customer.client.id].apiKey}?isSwipeOnly=true&customerId=${customer.id}`);
              }
            "
              >
                Swipe
              </button>
            </td>
            <td v-if="virtualTerminalPermissions">
              <button
                  v-if="customer?.has_customer"
                :disabled="!userMerchantsByMerchant[customer.client.id]"
                type="button"
                class="btn btn-dark"
                @click="
              () => {
                router.push(`/virtual-terminal/${userMerchantsByMerchant[customer.client.id].apiKey}?isSwipeAndCNP=true&customerId=${customer.id}`);
              }
            "
              >
                CNP
              </button>
            </td>
            <td>
              <button
                  v-if="customer?.has_customer"
                class="btn btn-primary"
                @click="openCustomerModal(customer, 'CUSTOMER')"
              >
                View All
              </button>
            </td>
            <td>
              <button
                  v-if="customer?.has_customer"
                class="btn btn-secondary"
                @click="openCustomerModal(customer, 'EDIT')"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <base-modal v-model="isCustomerModalOpen" title="Customer Payment Methods">
      <div name="default">
        <div v-if="selectedCustomer">
          <div class="customer-info">
            <h5>
              {{ selectedCustomer.first_name }} {{ selectedCustomer.last_name }}
            </h5>
            <p><strong>Email:</strong> {{ selectedCustomer.email }}</p>
            <p>
              <strong>Phone:</strong> {{ selectedCustomer.phone_number || '-' }}
            </p>

            <div
              v-if="selectedCustomer.billing_address_line_1"
              class="address-info"
            >
              <h6>Customer Billing Address:</h6>
              <p>
                {{ selectedCustomer.billing_address_line_1 }}<br />
                {{ selectedCustomer.billing_address_line_2 }}<br />
                {{ selectedCustomer.billing_locality }},
                {{ selectedCustomer.billing_region }}
                {{ selectedCustomer.billing_postal_code }}<br />
                {{ selectedCustomer.billing_country }}
              </p>
            </div>
            <div
              v-if="selectedCustomer.shipping_address_line_1"
              class="address-info"
            >
              <h6>Customer Shipping Address:</h6>
              <p>
                {{ selectedCustomer.shipping_address_line_1 }}<br />
                {{ selectedCustomer.shipping_address_line_2 }}<br />
                {{ selectedCustomer.shipping_locality }},
                {{ selectedCustomer.shipping_region }}
                {{ selectedCustomer.shipping_postal_code }}<br />
                {{ selectedCustomer.shipping_country }}
              </p>
            </div>
            <transition name="slide-y">
              <div v-if="selectedPaymentMethod" class="pb-3 pt-2">
                <h5>Selected Payment Method:</h5>
                <div style="display: flex; align-items: center">
                  <CardTableItem
                    :card-issuer="
                      parsePaymentMethod(selectedPaymentMethod).cardType
                    "
                    :last-four-digits="
                      parsePaymentMethod(selectedPaymentMethod).last4
                    "
                    :expiration-date="
                      parsePaymentMethod(selectedPaymentMethod).expirationDate
                    "
                  />
                  <button
                    type="button"
                    class="btn-close"
                    style="
                      margin-left: 8px;
                      color: red;
                      font-size: 20px;
                      cursor: pointer;
                    "
                    @click="selectedPaymentMethod = null"
                  ></button>
                </div>

                <div
                  v-if="selectedPaymentMethod.billing_address_line_1"
                  class="address-info"
                >
                  <h6>Method Billing Address:</h6>
                  <p>
                    {{ selectedPaymentMethod.billing_address_line_1 }}<br />
                    {{ selectedPaymentMethod.billing_address_line_2 }}<br />
                    {{ selectedPaymentMethod.billing_locality }},
                    {{ selectedPaymentMethod.billing_region }}
                    {{ selectedPaymentMethod.billing_postal_code }}<br />
                    {{ selectedPaymentMethod.billing_country }}
                  </p>
                </div>
              </div>
            </transition>
            <div
              v-if="selectedCustomer.billing_address_line_1 && false"
              class="address-info"
            >
              <h6>Default ACH Billing Address:</h6>
              <p>
                {{ selectedCustomer.billing_address_line_1 }}<br />
                {{ selectedCustomer.billing_address_line_2 }}<br />
                {{ selectedCustomer.billing_locality }},
                {{ selectedCustomer.billing_region }}
                {{ selectedCustomer.billing_postal_code }}<br />
                {{ selectedCustomer.billing_country }}
              </p>
            </div>
            <div
              v-if="selectedCustomer.shipping_address_line_1"
              class="address-info"
            >
              <h6>Shipping Address:</h6>
              <p>
                {{ selectedCustomer.shipping_address_line_1 }}<br />
                {{ selectedCustomer.shipping_address_line_2 }}<br />
                {{ selectedCustomer.shipping_locality }},
                {{ selectedCustomer.shipping_region }}
                {{ selectedCustomer.shipping_postal_code }}<br />
                {{ selectedCustomer.shipping_country }}
              </p>
            </div>
          </div>
          <hr />
          <h6>Payment Methods:</h6>
          <ul class="list-group">
            <li
              v-for="method in selectedCustomer.payment_methods"
              :key="method.id"
              class="list-group-item"
              :class="{
                selectedCard: selectedPaymentMethod === method,
                hoveredCard: false
              }"
            >
              <CardTableItem
                :card-issuer="parsePaymentMethod(method).cardType"
                :last-four-digits="parsePaymentMethod(method).last4"
                :expiration-date="parsePaymentMethod(method).expirationDate"
              />
            </li>
          </ul>
        </div>
      </div>
      <div name="footer">
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="closeCustomerModal">
            Close
          </button>
        </div>
      </div>
    </base-modal>
    <base-modal v-model="isEditCustomerModalOpen" title="Edit Customer" :is-large="true">
      <div>
        <div v-if="selectedCustomer">
          <div class="customer-info">
            <h5>
              Edit Customer
              {{ selectedCustomer.first_name }} {{ selectedCustomer.last_name }}
            </h5>

            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                  <label>email</label>
                  <input
                    v-model="selectedCustomer.email"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>first_name</label>
                  <input
                    v-model="selectedCustomer.first_name"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>last_name</label>
                  <input
                    v-model="selectedCustomer.last_name"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>phone_number</label>
                  <input
                    v-model="selectedCustomer.phone_number"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_region</label>
                  <input
                    v-model="selectedCustomer.billing_region"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_locality</label>
                  <input
                    v-model="selectedCustomer.billing_locality"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_address_line_1</label>
                  <input
                    v-model="selectedCustomer.billing_address_line_1"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_address_line_2</label>
                  <input
                    v-model="selectedCustomer.billing_address_line_2"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_region</label>
                  <input
                    v-model="selectedCustomer.shipping_region"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_locality</label>
                  <input
                    v-model="selectedCustomer.shipping_locality"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_address_line_1</label>
                  <input
                    v-model="selectedCustomer.shipping_address_line_1"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_address_line_2</label>
                  <input
                    v-model="selectedCustomer.shipping_address_line_2"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_country</label>
                  <input
                    v-model="selectedCustomer.billing_country"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_country</label>
                  <input
                    v-model="selectedCustomer.shipping_country"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>billing_postal_code</label>
                  <input
                    v-model="selectedCustomer.billing_postal_code"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>shipping_postal_code</label>
                  <input
                    v-model="selectedCustomer.shipping_postal_code"
                    class="form-control"
                    type="text" />
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <label>external_id</label>
                  <input
                    v-model="selectedCustomer.external_id"
                    class="form-control"
                    type="text" />
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h3>Meta Fields</h3>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="metaField in Object.keys(selectedCustomer.payment_request_meta).filter(s => s !== 'customFields')"
                  :key="metaField"
                  class="col-12 col-md-6 col-lg-3"
                >
                  <label>{{ metaField }}</label>
                  <input
                    v-model="selectedCustomer.payment_request_meta[metaField]"
                    class="form-control"
                    type="text"
                  />
                  <button
                    class="btn btn-sm btn-danger"
                    type="button"
                    @click="() => {
                      delete selectedCustomer.payment_request_meta[metaField]
                    }"
                  >
                    Remove Meta Field
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col" style="max-width: 200px;">
                  <input
                    v-model="newMetaField"
                    class="m-2 mb-0 form-control"
                    type="text"
                  />
                  <button class="m-2 mt-1 w-100 btn btn-success" type="button" @click="addMetaFieldToSelectedCustomer">
                    Add Meta Field
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h3>Custom Fields</h3>
                </div>
              </div>
              <div class="row">
                <div
                  v-for="metaField in Object.keys(selectedCustomer.payment_request_meta['customFields'])"
                  :key="metaField"
                  class="col-12 col-md-6 col-lg-3"
                >
                  <label>Custom Field "{{ metaField }}"</label>
                  <input
                    v-model="selectedCustomer.payment_request_meta['customFields'][metaField]"
                    class="form-control"
                    type="text"
                  />
                  <button
                    class="btn btn-sm btn-danger"
                    type="button"
                    @click="() => {
                      delete selectedCustomer.payment_request_meta['customFields'][metaField]
                    }"
                  >
                    Remove Custom Meta Field
                  </button>
                </div>
                <!--
                <div class="col-12 col-md-6 col-lg-3">
                  <label>payment_request_meta</label>
                    &lt;!&ndash;v-model="selectedCustomer.payment_request_meta"&ndash;&gt;
                  <input
                    :value="JSON.stringify(selectedCustomer.payment_request_meta)"
                    class="form-control"
                    type="text"
                    @input="selectedCustomer.payment_request_meta = JSON.stringify(selectedCustomer.payment_request_meta)"
                  />
                </div>
                -->
              </div>
              <div class="row">
                <div class="col" style="max-width: 200px;">
                  <input
                    v-model="newMetaCustomField"
                    class="m-2 mb-0 form-control"
                    type="text"
                  />
                  <button class="m-2 mt-1 w-100 btn btn-success" type="button" @click="addMetaCustomFieldToSelectedCustomer">
                    Add Meta Custom Field
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div name="footer">
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="closeCustomerModal">
              Close
            </button>
            <button class="btn btn-success" :disabled="isSavingCustomer" @click="saveCustomer">
              Save
            </button>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, onMounted, computed } from 'vue';
import useService from '@/composables/common/services';
import CardTableItem from '@/components/cards/CardTableItem.vue';
const { apiService } = useService();
import BaseModal from '@/components/base/BaseModal.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import BaseButton from "@/components/base/BaseButton.vue";

// Existing code
const searchParameter = ref('');
import { useStore } from 'vuex';
import { PERMISSION_PRIMITIVES } from '@/config/constants';
import http from "@/services/http";
import {router} from "@/router";
import { useToast } from 'vue-toastification';

// Setup Stuff for Virtual Terminal
// Get user data
const store = useStore();
const defaultClientName = computed(() => store.getters.defaultClientid);
const defaultClientId = computed(() => store.getters.defaultClientId);
const userHasVirtualTerminalPermission = computed(() => {
  return store.getters.grantedPermissions.includes(PERMISSION_PRIMITIVES.VIRTUAL_TERMINAL);
});
const hasPermissions = computed(() => store.getters.merchantHasPermissions);

/**
 * Marks if the user has permissions to use the virtual terminal.
 * True if merchant has no permissions configured or
 * if the user has the virtual terminal permission.
 */
const virtualTerminalPermissions = computed(() => {
  if (userHasVirtualTerminalPermission.value) {
    return true;
  } else {
    return !hasPermissions.value;
  }
});

const role = computed(() => store.getters.role);

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   apiKey: string
 * }} VaultPageUserMerchant
 */

// noinspection JSValidateTypes
/**
 * @type {Ref<UnwrapRef<VaultPageUserMerchant[]>>}
 */
const userMerchants = ref([]);

/**
 * @type {import("vue").ComputedRef<Record<string, VaultPageUserMerchant>>}
 */
const userMerchantsByMerchant = computed(() => {
  return userMerchants.value.reduce((acc, next) => {
    acc[next.id] = next;
    return acc;
  }, {});
}, {});

const fetchUserMerchants = () => {
  http
      .get('/api/user/apikeys')
      .then((response) => {
        // test for empty list
        const merchantData = response.data.message;

        if (merchantData) {
          userMerchants.value = merchantData
              .map((merchant) => ({
                id: merchant.id,
                name: merchant.name,
                apiKey: merchant.apikey,
                apikey: merchant.apikey
              }))
              .sort((a, b) =>
                  a.name && b.name ? a.name.localeCompare(b.name) : 0
              );
        }
      })
      .catch((error) => {
        console.error('Error fetching user merchant data:', error);
      });
};

fetchUserMerchants();

const defaultApiKey = computed(() => {
  console.log(
      'Fetching default API key...',
      userMerchants.value,
      defaultClientId.value
  );
  const defaultMerchant = userMerchants.value.find(
      (merchant) => merchant.id === defaultClientId.value
  );
  if (defaultMerchant) {
    console.log(`Default API key found: ${defaultMerchant.apikey}`);
    return defaultMerchant.apikey;
  } else {
    console.log('No default API key found.');
    return '';
  }
});
console.log('Default Client Name:', defaultClientName.value);
console.log('Default Client ID:', defaultClientId.value);
console.log('Role:', role.value);
console.log('Default API Key:', defaultApiKey.value);

// Existing code Specific To Vault

const isCustomerModalOpen = ref(false);
const isEditCustomerModalOpen = ref(false);

/**
 * @typedef {{
 *   id: string
 *   updated_at: string
 *   created_at: string
 *   is_deleted: string
 *   identifier: string
 *   token: string
 * }} PaymentMethodModel
 */

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   email: string
 *   origin: string
 *   last_name: string
 *   first_name: string
 *   phone_number: string
 *   billing_region: string
 *   billing_locality: string
 *   billing_address_line_1: string
 *   billing_address_line_2: string
 *   shipping_region: string
 *   shipping_locality: string
 *   shipping_address_line_1: string
 *   shipping_address_line_2: string
 *   billing_country: string
 *   shipping_country: string
 *   billing_postal_code: string
 *   shipping_postal_code: string
 *   external_id: string
 *   payment_request_meta: Object
 *   client: {
 *     id: string
 *     name: string
 *   }
 *   payment_methods: Array<PaymentMethodModel>
 *   transactions: Array<PaymentTransactionModel>
 *   customer_wallet: PaymentWalletModel
 * }} PaymentCustomerModel
 */

/**
 * @typedef {PaymentCustomerModel} VaultPageCustomer
 */

/**
 * @type {import("vue").Ref<VaultPageCustomer>}
 */
const selectedCustomer = ref(null);
const customersLoading = ref(false);

const selectedPaymentMethod = ref(null);

async function runSearch() {
  const param = searchParameter.value;
  customersLoading.value = true;
  try {
    const response = await apiService.get(
        `api/payee/all?external_id=${param}&first_name=${param}&last_name=${param}`
    );
    customers.value = [...response.message];
  } catch (error) {
    console.error('Error fetching customers:', error);
  } finally {
    customersLoading.value = false;
  }
}

const OPENABLE_MODALS = {
  'CUSTOMER': isCustomerModalOpen,
  'EDIT': isEditCustomerModalOpen,
}

/**
 *
 * @param customer
 * @param {'CUSTOMER' | 'EDIT'} modalName
 */
function openCustomerModal(customer, modalName = 'CUSTOMER') {
  customer.payment_request_meta = customer.payment_request_meta || {};
  customer.payment_request_meta.customFields = customer.payment_request_meta.customFields || {};
  selectedCustomer.value = customer;
  selectedPaymentMethod.value = null;
  let openableModal = OPENABLE_MODALS[modalName];
  if (openableModal)
    openableModal.value = true;
}

/**
 * TODO: add to modal's payment-method @click event
 * when we allow viewing card-specific details
 */
/*
function selectCustomerPaymentMethod(method) {
  selectedPaymentMethod.value = method;
}
*/

function closeCustomerModal() {
  selectedCustomer.value = null;
  Object.values(OPENABLE_MODALS).forEach(v => v.value = false);
}

const toast = useToast();

const isSavingCustomer = ref(false);
const errorSavingCustomer = ref('');
async function saveCustomer() {
  // alert(JSON.stringify(selectedCustomer.value))
  isSavingCustomer.value = true;
  errorSavingCustomer.value = '';
  try {
    if (!selectedCustomer.value) {
      errorSavingCustomer.value = 'No customer data';
      return
    }

    await http({
      url: `/api/v1/vault/${selectedCustomer.value.id}`,
      data: selectedCustomer.value,
      config: { headers: { 'content-type': 'application/json' } },
      headers: { 'content-type': 'application/json' },
      method: 'PATCH'
    })
      .then((response) => {
        selectedCustomer.value = response.data.message;
      })
      .catch((error) => {
        console.error('Error saving customer:', error);
        errorSavingCustomer.value = 'Error saving customer: ' + error;
      });
  } finally {
    isSavingCustomer.value = false;
    if (errorSavingCustomer.value)
      toast.error(errorSavingCustomer.value);
    else
      toast.success('Saved Customer Successfully');
  }
}

/**
 * @type {import("vue").Ref<Array<VaultPageCustomer>>}
 */
const customers = ref([]);

async function fetchCustomers() {
  customersLoading.value = true;
  try {
    const response = await apiService.get('api/payee/all');
    customers.value = [...response.message];
  } catch (error) {
    console.error('Error fetching customers:', error);
  } finally {
    customersLoading.value = false;
  }
}

/**
 * @param {PaymentMethodModel} method
 * @returns {{last4: *, cardType: *, id, expirationDate: string}}
 */
function parsePaymentMethod(method) {
  // eslint-disable-next-line no-unused-vars
  const [_, cardType, last4, exp_month, exp_year] = method.token.split('-');
  return {
    id: method.id,
    cardType,
    last4,
    expirationDate: `${exp_month}/${exp_year}`
  };
}

/*
function getPaymentMethods(customer) {
  return customer.payment_methods.map(parsePaymentMethod);
}
*/

/**
 * @param {PaymentCustomerModel} customer
 */
function getFirstPaymentMethod(customer) {
  const firstMethod = customer.payment_methods[0];
  return firstMethod ? parsePaymentMethod(firstMethod) : null;
}

onMounted(() => {
  fetchCustomers();
});

const newMetaField = ref('');
function addMetaFieldToSelectedCustomer() {
  if (!selectedCustomer.value)
    return
  if (!newMetaField.value || newMetaField.value === 'customFields')
    return
  try {
    selectedCustomer.value.payment_request_meta[newMetaField.value] = '';
    newMetaField.value = '';
  } catch (ignored) {
    //
  }
}

const newMetaCustomField = ref('');
function addMetaCustomFieldToSelectedCustomer() {
  if (!selectedCustomer.value)
    return
  if (!newMetaCustomField.value)
    return
  try {
    selectedCustomer.value.payment_request_meta["customFields"][newMetaCustomField.value] = '';
    newMetaCustomField.value = '';
  } catch (ignored) {
    //
  }
}
</script>

<style lang="scss">
@import '@/styles/scss_variables.scss';

.dashboard-title {
  font-size: 24px;
  color: #383838;
}

.customer-table {
  margin-top: 20px;
  table-layout: fixed;
  overflow-y: scroll;
}

.customer-table td {
  word-wrap: break-word;
}

.customer-info {
  margin-bottom: 20px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 5px;
  }

  .address-info {
    margin-top: 15px;

    h6 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.selectedCard {
  background-color: #f6951e; //var(--primary);
}

/* TODO: this class is now marked false on the payment
method card. mark it as true when viewing a specific card's
payment details becomes available */
.hoveredCard:hover {
  background-color: #3a68ff; // var(--blue-primary);
  cursor: pointer;
}
</style>

<template>
  <span class="title">Users</span>
  <div class="clients management-page">
    <div class="mt-1 position-relative">
      <div class="d-flex align-items-center">
        <div
          v-if="role === 'admin' || role === 'parent'"
          class="form-floating mb-2 ms-2"
        >
          <select
            class="form-select"
            id="merchantDropdown"
            v-model="selectedMerchantId"
            @change="handleMerchantChange"
            style="width: 300px"
          >
            <option value="" disabled>Select an Account</option>
            <option
              v-for="merchant in merchants"
              :key="merchant.id"
              :value="merchant.id"
            >
              {{ merchant.name }}
            </option>
          </select>
          <label for="merchantDropdown">Viewing Users For:</label>
        </div>
        <base-button class="ms-auto add-user" @click="showAddUserModal">
          <span class="fz-16 fw-500 vertical-align-middle"> + </span>
          <span class="vertical-align-middle">Add user</span></base-button
        >
      </div>
      <!-- this was an attribute passed to AgGrid: -->
      <!--@row2Selected="rowDetails"-->
      <AgGrid
        ref="agref"
        :apiurl="apiurl"
        :defineDefs="columnDefs"
        group-panel=""
        :excelOptions="{ fileName: 'Users.xlsx' }"
        height="500px"
        @filterChanged="filterModified"
        :columns="[]"
        :sideBar="true"
      ></AgGrid>
    </div>
  </div>
  <base-modal :is-large="true" v-model="isEditingUserModalOpen" title="Edit User">
    <div name="default">
      <div v-if="editingUser">
        <form class="position-relative" @submit.prevent="submit">
          <!-- the label-->
          <div class="form-group mb-2">
            <label class="form-label text-align-center">
              Edit User
            </label>
          </div>

          <div class="form-group mb-2">
            <label for="firstName">First Name:</label>
            <input
                type="text"
                class="form-control"
                id="firstName"
                v-model="editingUser.account.first_name"
                required
            />
          </div>

          <div class="form-group mb-2">
            <label for="userName">Last Name:</label>
            <input
                type="text"
                class="form-control"
                id="userName"
                v-model="editingUser.account.last_name"
                required
            />
          </div>

          <div class="form-group mb-2">
            <label for="email">Email Address:</label>
            <input
                type="email"
                class="form-control"
                id="email"
                v-model="editingUser.email"
                required
            />
          </div>

          <hr style="height: 10px; background-color: #333; color: #333; border-radius: 5px;" />

          <!--
          this will edit merchant members
          -->

          <!-- list of merchants for removing: -->
          <div v-if="isAlpha" class="form-group">
            <label>Merchants:</label>
            <div class="groups-list">
              <!-- d-flex align-items-center -->
              <span
                  v-for="entity in editingUser.account.memberships"
                  :key="entity.merchant.id"
                  class="badge bg-success me-1"
              >
                {{ entity.merchant.name }}
                <button
                    type=""
                    class="btn btn-sm btn-danger ms-2"
                    @click="removeEditingUserFromMerchant(entity)"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                  >
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </button>
                <!--<label>User Role:</label>-->
                <select
                    v-model="entity.role.id"
                    class="form-control me-2"
                >
                  <option value="">Select a role</option>

                  <option
                      v-for="permissionRole in availableRolesForMerchantsInMemberships[entity.merchant.id]"
                      :key="permissionRole.id"
                      :value="permissionRole.id"
                  >
                    {{ permissionRole.name }}
                  </option>
                </select>
              </span>
            </div>
          </div>
          <!-- label to add merchant -->
          <div v-if="isAlpha" class="form-group">
            <label for="email">Select Merchant to Add:</label>
          </div>
          <!-- select box with available merchants -->
          <div v-if="isAlpha"  class="form-group">
            <div class="input-group">
            <select
                class="form-select me-2"
                id="merchantDropdown"
                v-model="editingUserSelectedMerchant"
                @change="assignEditingUserToMerchant(editingUserSelectedMerchant)"
            >
              <option value="" disabled>Select an Account</option>
              <option
                  v-for="merchant in availableMerchants"
                  :key="merchant.id"
                  :value="merchant"
              >
                {{ merchant.name }}
              </option>
            </select>
            <button type="button" class="btn btn-primary ms-2" @click="toggleAllMerchants">
              {{ allMerchantsAreAllSelected ? 'Remove All' : 'Add All' }}
            </button>
          </div>
          </div>

          <hr style="height: 10px; background-color: #333; color: #333; border-radius: 5px;" />

          <!-- this will edit the user's role -->
          <!-- pick role merchant (select element) -->
          <div v-if="isAlpha" class="form-group">
            <label for="email">Select User's default merchant:</label>
            <select
              id="userRoleMerchant"
              v-model="editingUser.account.default_client.id"
              class="form-control me-2"
            >
              <!-- you can debug this select by adding a change attribute: -->
              <!--@change="console.log(merchantForFilteringAvailableUserRoles)"-->
              <option value="null">Select User's default merchant:</option>

              <option
                v-for="merchant in totalAvailableMerchants"
                :key="merchant.id"
                :value="merchant.id"
              >
                {{ merchant.name }}
              </option>
            </select>
          </div>
          <!-- pick role (select element) -->
          <!--
          <div v-if="isAlpha" class="form-group mb-2">
            <label for="userRole">User Role:</label>
            <select
                id="userRole"
                v-model="editingUser.role.id"
                class="form-control me-2"
            >
              <option value="">Select a role</option>

              <option
                  v-for="permissionRole in roles"
                  :key="permissionRole.id"
                  :value="permissionRole.id"
              >
                {{ permissionRole.name }} ({{permissionRole.merchant.name}})
              </option>
            </select>
          </div>
          -->

          <hr style="height: 10px; background-color: #333; color: #333; border-radius: 5px;" />

          <!-- locations to remove -->
          <div v-if="isAlpha" class="form-group">
            <label>Locations:</label>
            <div class="groups-list">
              <!-- d-flex align-items-center -->
              <span
                  v-for="entity in editingUser.sub_entities?.sort((a, b) => a.name.localeCompare(b.name))"
                  :key="entity.id"
                  class="badge bg-success me-1"
              >
                  {{ entity.name }} ({{
                    entity.merchant.name.match(/athletico .+\d+/i)
                      ? entity.merchant.name.replaceAll(/\D+/g, '')
                      : entity.merchant.name
                  }})
                  <button
                      type=""
                      class="btn btn-sm btn-danger ms-2"
                      @click="removeEditingUserFromSubLocation(entity.id)"
                  >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                    >
                      <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </span>
            </div>
          </div>
          <!-- locations label -->
          <div v-if="isAlpha" class="form-group">
            <label>Assign Location to User:</label>
          </div>
          <!-- locations editing -->
          <div v-if="isAlpha" class="form-group">
            <div class="input-group">
            <select
                v-model="selectedUserSubLocation"
                class="form-select me-2"
                @change="
                  selectedUserSubLocation && assignEditingUserToSubLocation(selectedUserSubLocation)
                "
            >
              <option disabled value="">Select a SubLocation</option>
              <option
                  v-for="group in availableSubLocations"
                  :key="group"
                  :value="group"
              >
                {{ group.name }} ({{ group.merchant?.name }})
              </option>
            </select>
            <button type="button" class="btn btn-primary ms-2" @click="toggleAllLocations">
              {{ allLocationsAreAllSelected ? 'Remove All' : 'Add All' }}
            </button>
            </div>
          </div>

          <button
              class="mt-4 w-100 btn btn-primary"
              type="submit"
              @click="submitEditUser"
          >
            Save User
          </button>
        </form>
      </div>
    </div>
  </base-modal>
  <div
    class="modal fade"
    tabindex="-1"
    id="myModal"
    ref="myModal"
    aria-labelledby="subsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="subsLabel">Add User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form class="position-relative" @submit.prevent="submit">
            <div class="form-group mb-2">
              <label v-if="isNewUserMerchantSelected" class="form-label text-align-center">
                Add A New User
              </label>
              <label v-if="!isNewUserMerchantSelected" class="form-label text-align-center">
                Select A Merchant To Add A New User
              </label>
            </div>

            <div class="form-group mb-2">
              <label for="userName">Name:</label>
              <input
                type="text"
                class="form-control"
                id="userName"
                v-model="userName"
                required
              />
            </div>

            <div class="form-group mb-2">
              <label for="email">Email Address:</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
                required
              />
            </div>

            <div v-if="isAlpha"  class="form-group mb-2">
              <label for="email">Select Merchant:</label>
              <select
                  class="form-select me-2"
                  id="merchantDropdown"
                  v-model="selectedMerchantId"
                  @change="getRolesBySelectedMerchantId"
              >
                <option value="" disabled>Select an Account</option>
                <option
                    v-for="merchant in merchants"
                    :key="merchant.id"
                    :value="merchant.id"
                >
                  {{ merchant.name }}
                </option>
              </select>
            </div>

            <div v-if="isAlpha && isNewUserMerchantSelected" class="form-group mb-2">
              <label for="userRole">User Role:</label>
              <select
                id="userRole"
                v-model="userRole"
                class="form-control me-2"
              >
                <option value="">Select a role</option>

                <option
                  v-for="permissionRole in roles.sort((a, b) => a.merchant.name.localeCompare(b.merchant.name) || a.name.localeCompare(b.name))"
                  :key="permissionRole.id"
                  :value="permissionRole.id"
                >
                  {{ permissionRole.name }} ({{permissionRole.merchant.name}})
                </option>
              </select>
            </div>
            <div v-if="isAlpha && isNewUserMerchantSelected" class="form-group">
              <label>Locations:</label>
              <div class="groups-list">
                <!-- d-flex align-items-center -->
                <span
                  v-for="group in userSubLocations"
                  :key="group.id"
                  class="badge bg-success me-1"
                >
                  {{ subLocations.find((g) => g.id === group.id)?.name }}
                  <button
                    type=""
                    class="btn btn-sm btn-danger ms-2"
                    @click="removeUserFromSubLocation(group.id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
            <div v-if="isAlpha && isNewUserMerchantSelected" class="form-group">
              <label>Assign Location to User:</label>
            </div>
            <div v-if="isAlpha && isNewUserMerchantSelected" class="form-group">
              <div class="input-group">
              <select
                v-model="selectedUserSubLocation"
                class="form-select me-2"
                @change="
                  selectedUserSubLocation && assignUserToSubLocation(selectedUserSubLocation)
                "
              >
                <option disabled value="">Select a SubLocation</option>
                <option
                  v-for="group in availableNewUserSubLocations"
                  :key="group"
                  :value="group"
                >
                  {{ group.name }} ({{ group?.merchant.name }})
                </option>
              </select>
              <button type="button" class="btn btn-primary ms-2" @click="toggleAllLocationsForNewUsers">
                {{ allLocationsAreAllSelected ? 'Remove All' : 'Add All' }}
              </button>
              </div>
            </div>

            <button v-if="isNewUserMerchantSelected || !isAlpha"
              class="mt-4 w-100 btn btn-primary"
              type="submit"
              @click="submitNewUser"
            >
              Add User
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// New below
import { computed, ref, reactive, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import http from '@/services/http';
import useService from '@/composables/common/services';
import AgGrid from '@/components/AgGrid.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Modal from 'bootstrap/js/dist/modal';
import { useToast } from 'vue-toastification';
import Confirm from '@/components/ConfirmComponent.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseModal from "@/components/base/BaseModal.vue";
import { computedAsync } from '@vueuse/core'

const toast = useToast();
let toastdefault = {
  position: 'top-center',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
};

// Get user data
const store = useStore();
const defaultClientName = store.getters.defaultClientName;
const defaultClientid = computed(() => store.getters.defaultClientId);
const isAlpha = computed(() => store.getters.merchantHasPermissions);

const role = store.getters.role;

// Initialize selectedMerchantId and selectedMerchantName with default values
const selectedMerchantId = ref('');
const selectedMerchantName = ref(defaultClientName);

/**
 * this variable is used for searching for roles.
 * It stores the merchant id to filter selectable roles by.
 *
 * @type {import("vue").Ref<
 *   {
 *    id: string
 *    name: string
 *   }
 *   | null
 * >}
 */
const merchantForFilteringAvailableUserRoles = ref(null);

const agref = ref();

const myModal = ref(null);
/**
 *
 * @type {import("vue").Ref<Array<{
 *   id: string
 *   name: string
 * }>>}
 */
const merchants = ref([]);
/**
 * UserAgGridParams is the type of array element returned by apiUrl,
 * wrapped with "data" field by AgGrid.
 *
 * @typedef {{
 *   data: EditingUser
 * }} UserAgGridParams
 */
const apiurl = ref(`/api/client/users`);

// selection of all locations
const allLocationsSelected = ref(false);
const allLocationsAreAllSelected =
  computed(() => subLocations.value.length === editingUser.value.sub_entities?.length);

/*
 *
 *         {
 *             "id": "b9b51a07-05ce-4cb4-aa12-5382181ba4b3",
 *             "is_deleted": false,
 *             "created_at": "2024-05-29T13:50:57.146003+00:00",
 *             "updated_at": "2024-05-29T13:50:57.146003+00:00",
 *             "email": "9449jstreet@athletico.com",
 *             "email_confirmed": false,
 *             "label": null,
 *             "firebase_user_id": null,
 *             "user_type": "user",
 *             "is_locked_out": false,
 *             "is_two_factor_enabled": false,
 *             "account": {
 *                 "id": "b9b51a07-05ce-4cb4-aa12-5382181ba4b3",
 *                 "is_deleted": false,
 *                 "created_at": "2024-05-29T14:25:42.451092+00:00",
 *                 "updated_at": "2024-05-29T14:25:42.451092+00:00",
 *                 "phone_number": null,
 *                 "phone_confirmed": false,
 *                 "first_name": "Clinic",
 *                 "last_name": "Omaha94thandJ",
 *                 "billing_region": null,
 *                 "billing_locality": null,
 *                 "billing_address_line_1": null,
 *                 "billing_address_line_2": null,
 *                 "shipping_region": null,
 *                 "shipping_locality": null,
 *                 "shipping_address_line_1": null,
 *                 "shipping_address_line_2": null,
 *                 "billing_country": "US",
 *                 "shipping_country": "US",
 *                 "billing_postal_code": "0",
 *                 "shipping_postal_code": "0"
 *             },
 *             "role": {
 *                 "id": "51117f7a-3fb7-4979-a1b2-ea539de974cd",
 *                 "is_deleted": false,
 *                 "created_at": "2024-05-30T14:34:04.570293+00:00",
 *                 "updated_at": "2024-05-30T14:34:04.570296+00:00",
 *                 "name": "Clinic",
 *                 "merchant": {
 *                     "id": "45a43bc7-19e9-4e44-be77-3334d79fbc7b",
 *                     "is_deleted": false,
 *                     "created_at": "2024-06-03T22:51:29.634885+00:00",
 *                     "updated_at": "2024-06-03T22:51:29.634893+00:00",
 *                     "level": "Merchant",
 *                     "tax_id": null,
 *                     "website": null,
 *                     "email": null,
 *                     "phone": null,
 *                     "name": "TEST Athletico Nebraska-5",
 *                     "dba_name": "TEST Athletico Nebraska-5",
 *                     "legal_name": "TEST Athletico Nebraska-5",
 *                     "region": null,
 *                     "locality": null,
 *                     "address_line_1": null,
 *                     "address_line_2": null,
 *                     "postal_code": null,
 *                     "ssn": null,
 *                     "primary": null,
 *                     "stock_symbol": null
 *                 }
 *             },
 *             "sub_entities": [
 *                 {
 *                     "id": "e4d0aa08-1f67-4cab-8e3d-c9761ba5fe5c",
 *                     "is_deleted": false,
 *                     "created_at": "2020-12-25T00:00:00+00:00",
 *                     "updated_at": "2024-06-05T15:46:54.403498+00:00",
 *                     "name": "Omaha 94th and J",
 *                     "address": "{\"address1\" : \"Athletico Physical Therapy-\", \"address2\" : \"Omaha 94th and J Clinic\", \"address\" : \"9449 J St.\", \"city\" : \"Omaha\", \"st\" : \"NE\", \"zip\" : \"68127\"}",
 *                     "phone": "402-593-7345",
 *                     "payment_type": "cc"
 *                 }
 *             ],
 *             "frontend_merchants_rbac_access": [
 *                 {
 *                     "id": "45a43bc7-19e9-4e44-be77-3334d79fbc7b",
 *                     "name": "TEST Athletico Nebraska-5"
 *                 }
 *             ]
 *         }
 */

/**
 * @typedef {{
 *   id: string
 *   order: int
 *   name: string
 *   type: string
 *   values?: Array<string>
 *   required: boolean
 *   is_hidden: boolean
 *   name: string
 * }} CustomField
 */

/**
 * @typedef {{
 *  id: string
 *  merchant: {
 *    id: string
 *    name: string
 *  }
 *  role?: {
 *    id: string | null
 *    name: string | null
 *    merchant: {
 *      id: string
 *      name: string
 *    }
 *    granted_sub_entities?: Array<{
 *      id: string
 *      name: string
 *    }>
 *    granted_permissions?: Array<{
 *      id: string
 *      name: string
 *    }>
 *    omittable_fields?: Array<CustomField>
 *    custom_field_access?: Record<string, 'show' | 'require'>
 *  }
 *}} StoreUserMembership
 */

/**
 * @typedef {StoreMerchantModel} SMC
 */

/**
 * @typedef {{
 *  id: string
 *  email: string
 *  user_type?: string
 *  account: {
 *    first_name: string
 *    last_name: string
 *    default_client: {
 *      id: string
 *      merchant: SMC
 *    }
 *    memberships?: Array<StoreUserMembership>
 *  }
 *  frontend_merchants_rbac_access: Array<{id: string, name: string}>
 *  sub_entities?: Array<{
 *    id: string
 *    name: string
 *    merchant: {
 *      id: string
 *      name: string
 *    }
 *  }>
 * }} EditingUser
 */
/**
 * @type {import("vue").Ref<EditingUser>}
 */
const editingUser = ref({});
const isEditingUserModalOpen = ref(false);
const isNewUserMerchantSelected = ref(false);

// Additional Merchants for Editing Users
/**
 * @type {import("vue").Ref<{
 *   id: string
 *   name: string
 * } | null>}
 */
const editingUserSelectedMerchant = ref(null);
const editingUserMerchants = ref([]);
const allMerchantsSelected = ref(false);
const allMerchantsAreAllSelected =
  computed(function allMerchantsAreAllSelectedComputed() {
    console.log('allMerchantsAreAllSelectedComputed')
    return availableMerchants.value?.length === editingUser.value.account.memberships?.length;
  });

const userName = ref('');
const email = ref('');
const user_type = ref('');
const label = ref('');
const userRole = ref('');
const userGroupsSet = ref(new Set());
const userSubLocationsSet = ref(new Set());
const userSubLocations = computed(() => Array.from(userSubLocationsSet.value));
const selectedUserSubLocation = ref('');
const defaultClientId = computed(() => store.getters.defaultClientId);
const groups = ref([]);

/**
 * @typedef {{
 *   id: string
 *   name: string
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 * }} SubLocation
 */

// noinspection JSValidateTypes
/**
 * @type {import("vue").Ref<Array<SubLocation>>}
 */
const subLocations = computedAsync(async function subLocationsComputedAsync() {
  console.log('subLocationsComputedAsync')
  if (!selectedMerchantId?.value)
    return [];
  let m_ids = editingUser.value?.account?.memberships?.map(e => e.merchant.id)?.filter(Boolean) || []
  let result = await http.get(`/api/rbac/${selectedMerchantId.value}/sub-entities?merchant_ids=${m_ids}`);
  return result.data.message;
}, []);

const roles = computedAsync(async () => {
  if (!merchantForFilteringAvailableUserRoles.value?.id)
    return [];
  let response = await http.get(`/api/rbac/${merchantForFilteringAvailableUserRoles.value.id}/roles`);
  return response.data.message;
}, []);

/**
 * @type {Modal}
 */
const modalInstance = ref(null);
const loadingPermissionsPageData = ref(false);

/**
 * @typedef {SubLocation} Role
 */

// noinspection JSValidateTypes
/**
 * @type {import("vue").Ref<Record<string, Array<Role>>>}
 */
const availableRolesForMerchantsInMemberships = computedAsync(async function getRolesForMemberMerchants() {
  if (!editingUser.value) return {}
  let mIds = editingUser.value.account.memberships?.map(e => e.merchant.id) || [];
  if (!mIds) return {}

  /**
   *
   * @type {Array<PromiseSettledResult<Record<string, Array<Role>>>>}
   */
  let results = await Promise.allSettled(mIds.map(async mId => {
    return await http.get(`/api/rbac/${mId}/roles?with_hierarchy=false`)
      .then(r => ({
        [mId]: r.data.message.map(m => ({
          id: m.id,
          name: m.name,
          merchant: {
            id: m.merchant?.id,
            name: m.merchant?.name,
          }
        }))
      }));
  }));

  let errors = results.filter(r => r.status === "rejected");
  if (errors.length) {
    console.error("errors fetching availableRolesForMerchantsInMemberships", { errors });
    console.log("errors fetching availableRolesForMerchantsInMemberships", { errors });
  }

  // noinspection UnnecessaryLocalVariableJS
  let newValue = results.filter(r => r.status === "fulfilled")
    .map(
      /**
       * @param {PromiseFulfilledResult<Record<string, Role[]>>} r
       * @return {Record<string, Array<Role>>}
       */
      function (r) {
        // noinspection JSValidateTypes
        return r.value;
      }
    )
    .reduce(
      /**
       * combine all records
       *
       * @param {Record<string, Array<Role>>} acc
       * @param {Record<string, Array<Role>>} next
       * @return {Record<string, Array<Role>>}
       */
      (acc, next) => Object.assign(acc, next),
      {}
    );

  // console.log('new value for availableRolesForMerchantsInMemberships:', newValue);
  return newValue;
}, {});

const totalAvailableMerchants = computed(() => {
  // fmra useful for this but need to make sure it is added on backend
  let defaultId = store.state.user?.account?.default_client?.merchant;
  let members = store.state.user?.account?.memberships?.map(s => s.merchant) || [];
  return members.concat(defaultId).filter((e, i, a) => a.indexOf(e) === i);
}, []);

const availableMerchants = computed(() => {
  // and what we can have
  let object = totalAvailableMerchants.value
    .reduce((acc, next) => { acc[next.id] = next; return acc; }, {});

  // and what we already have
  let editing = new Set(editingUser.value?.account?.memberships?.map(e => e.merchant.id) || []);

  return Object.values(object).filter(m => !editing.has(m.id));
}, []);

const availableSubLocations = computed(() => {
  console.log('availableSubLocations');
  const editingUserIds = editingUser.value.sub_entities?.map(s => s.id) || [];
  return subLocations.value.filter(subLocation => {
    return !editingUserIds.includes(subLocation.id);
  });
});

const availableNewUserSubLocations = computed(() => {
  const editingUserIds = userSubLocations.value.map(s => s.id);
  return subLocations.value.filter(subLocation => {
    return !editingUserIds.includes(subLocation.id);
  });
});

function addAllLocations(forNewUser) {
  if (forNewUser) {
    const existingSubEntityIds = userSubLocations.value.map(s => s.id);
    console.log("ExistingSubEntityIds: ", existingSubEntityIds);
    subLocations.value.forEach(sub => {
      if (!existingSubEntityIds.includes(sub.id)) {
        assignUserToSubLocation(sub)
      }
    });
    allLocationsSelected.value = true;
  } else {
    console.log("Adding all locations: ", subLocations);
    console.log("Into User Selection: ", editingUser.value.sub_entities);
    const existingSubEntityIds = editingUser.value.sub_entities.map(s => s.id);
    console.log("ExistingSubEntityIds: ", existingSubEntityIds);
    subLocations.value.forEach(sub => {
      if (!existingSubEntityIds.includes(sub.id)) {
        assignEditingUserToSubLocation(sub)
      }
    });
    allLocationsSelected.value = true;
    console.log("Into User Selection: ", editingUser.value.sub_entities);
  }
}

function removeAllLocations(forNewUser) {
  if (forNewUser) {
    userSubLocationsSet.value.clear();
    allLocationsSelected.value = false;
  } else {
    editingUser.value.sub_entities = [];
    allLocationsSelected.value = false;
  }
}

function toggleAllLocations() {
  if (allLocationsSelected.value) {
    removeAllLocations();
  } else {
    addAllLocations();
  }
}

function toggleAllLocationsForNewUsers() {
  if (allLocationsSelected.value) {
    removeAllLocations(true);
  } else {
    addAllLocations(true);
  }
}

function removeAllMerchants() {
    editingUser.value.account.memberships = [];
    allLocationsSelected.value = false;
}

function addAllMerchants() {
  console.log("Adding all merchants: ", merchants);
  console.log("Into User Selection: ", editingUser.value.account.memberships);
  const existingMerchantIds = editingUser.value.account.memberships.map(s => s.merchant.id);
  console.log("existingMerchantIds: ", existingMerchantIds);
  merchants.value.forEach(sub => {
    if (!existingMerchantIds.includes(sub.id)) {
      assignEditingUserToMerchant(sub);
    }
  });
  allMerchantsSelected.value = true;
  console.log("Into User Selection: ", editingUser.value.account.memberships);
}

function toggleAllMerchants() {
  if (allMerchantsSelected.value) {
    removeAllMerchants();
  } else {
    addAllMerchants();
  }
}

function checkAllMerchantsSelected() {
  if (!editingUser.value) return;
  allMerchantsSelected.value = editingUserMerchants.value.length === editingUser.value.account.memberships?.length;
}

function checkAllLocationsSelected() {
  allLocationsSelected.value = subLocations.value.length === editingUser.value.sub_entities?.length;
}

function checkAllLocationsSelectedForNewUser() {
  allLocationsSelected.value = subLocations.value.length === userSubLocations.value.length;
}

async function getGroups() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/groups`);

  groups.value = response.data.message;

  console.log('Groups response', response.data);
}

async function getSubLocations() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/sub-entities`);

  groups.value = response.data.message;

  console.log('Sub Entities response', response.data);
}

async function getRoles() {
  const response = await http.get(`/api/rbac/${defaultClientId.value}/roles`);

  roles.value = response.data.message;

  console.log('Roles response', response.data);
}

const getRolesBySelectedMerchantId = async () => {

  // clear old locations for new merchant locations
  userSubLocationsSet.value.clear();

  const response = await http.get(`/api/rbac/${selectedMerchantId.value}/roles`);

  roles.value = response.data.message;

  console.log('Roles response', response.data);

  const subLocationsResponse = await http.get(`/api/rbac/${selectedMerchantId.value}/sub-entities`);

  subLocations.value = subLocationsResponse.data.message;

  console.log('Sublocations response', response.data);

  isNewUserMerchantSelected.value = true;
}

const newUser = reactive({
  role: {},
  groups_member: []
});

watch([() => defaultClientId.value], ([newClientId], [oldClientId]) => {
  // on first load, handles null merchant ID to update on incoming client ID
  // and refresh data

  if (newClientId && !oldClientId) {
    loadPermissionsData();
  }
});

watch(editingUser, () => {
  checkAllLocationsSelected();
  checkAllMerchantsSelected();

  // initialize so that vue can be reactive
  if (editingUser.value) editingUser.value.sub_entities = editingUser.value.sub_entities || [];
}, { deep: true })

watch(userSubLocations, () => {
  checkAllLocationsSelectedForNewUser();
}, { deep: true })

async function loadPermissionsData() {
  loadingPermissionsPageData.value = true;
  try {
    await Promise.all([getGroups(), getRoles(), getSubLocations()]);
  } catch (error) {
    console.error('Failed to load permissions page data:', error);
  } finally {
    loadingPermissionsPageData.value = false;
  }
}
if (defaultClientId.value) {
  loadPermissionsData();
}

const assignUserToGroup = (selectedAddGroup) => {
  userGroupsSet.value.add(selectedAddGroup);
};

const removeUserFromGroup = (groupId) => {
  userGroupsSet.value.delete(groupId);
};

const assignUserToSubLocation = (selectedSubLocation) => {
  userSubLocationsSet.value.add(selectedSubLocation);
  checkAllLocationsSelectedForNewUser();
};

const assignEditingUserToSubLocation = (selectedSubLocation) => {
  console.log("assignEditingUserToSubLocation selectedSubLocation: ", selectedSubLocation);
  editingUser.value.sub_entities.push(selectedSubLocation);
  checkAllLocationsSelected();
  console.log("assignEditingUserToSubLocation editingUser.value.sub_entities: ", editingUser.value.sub_entities);
};

/**
 * @param {{
 *   id: string
 *   name: string
 * }} selectedMerchant
 * @returns {Promise<void>}
 */
const assignEditingUserToMerchant = async (selectedMerchant) => {
  // edit the user
  console.log(selectedMerchant);
  if (!selectedMerchant) return;
  editingUser.value.account.memberships = editingUser.value.account.memberships || [];
  const currentAccountMerchants = editingUser.value.account.memberships;
  editingUser.value.account.memberships.push({
    // perform the action
    merchant: selectedMerchant,
    // create the object for use in v-model for role selection
    role: {
      // the backend tolerates null for this value
      id: null,
      // ignored by the backend
      name: null
    }
  });
  editingUserMerchants.value.push(selectedMerchant);

  // callback from editing user
  checkAllMerchantsSelected();

  // now we can manage more sub-entities
  // this now happens as a computedAsync
  // const subLocationsResponse = await http.get(`/api/rbac/${selectedMerchant.id}/sub-entities`);
  // const newSubLocationsResponsePushed = subLocationsResponse.data.message;
  // newSubLocationsResponsePushed.forEach(r => subLocations.value.push(r));

  // report on what just happened
  console.log(
    "assignEditingUserToMerchant",
    {
      selectedMerchant,
      currentAccountMerchants,
      newAccountMerchants: editingUser.value.account.memberships,
      newSubLocationsResponsePushed: editingUser.value.account.memberships,
    }
  );
};

/**
 * @param {{
 *   merchant: {
 *     id: string
 *     name: string
 *   }
 * }} selectedAccount
 */
const removeEditingUserFromMerchant = (selectedAccount) => {
  const selectedMerchant = selectedAccount.merchant
  // edit the user
  editingUser.value.account.memberships = editingUser.value.account.memberships || [];
  const currentAccountMerchants = editingUser.value.account.memberships;

  // callback from editing user
  checkAllMerchantsSelected();

  // now we can manage less sub-entities
  subLocations.value = subLocations.value.filter(s => s.merchant.id !== selectedMerchant.id);

  // report on what just happened
  console.log(
    "removeEditingUserFromMerchant",
    {
      selectedMerchant,
      currentAccountMerchants,
      newAccountMerchants: editingUser.value.account.memberships,
      newSubLocationsResponsePushed: editingUser.value.account.memberships,
    }
  );

  console.log("removeEditingUserFromMerchant selectedMerchant.id: ", selectedMerchant.id);
  const updatedMerchants = editingUser.value.account.memberships
    .filter(entity => entity.merchant.id !== selectedMerchant.id);
  console.log("removeEditingUserFromMerchant updatedMerchants: ", updatedMerchants);
  editingUser.value.account.memberships = updatedMerchants;
  checkAllMerchantsSelected();
};

const removeUserFromSubLocation = (groupId) => {
  // Convert the set to an array
  const array = Array.from(userSubLocationsSet.value);

  // Filter out the item with the matching id
  const filteredArray = array.filter(item => item.id !== groupId);

  // Convert the array back to a set
  userSubLocationsSet.value = new Set(filteredArray);
  checkAllLocationsSelectedForNewUser();
};

const removeEditingUserFromSubLocation = (groupId) => {
  console.log("removeEditingUserFromSubLocation groupId: ", groupId);
  const updatedSubEntities = editingUser.value.sub_entities.filter(entity => entity.id !== groupId);
  console.log("removeEditingUserFromSubLocation updatedSubEntities: ", updatedSubEntities);
  editingUser.value.sub_entities = updatedSubEntities;
  checkAllLocationsSelected();
};

// Handle merchant selection change
const handleMerchantChange = () => {
  // Update selectedMerchantName when the user selects a merchant
  const selectedMerchant = merchants.value.find(
    (merchant) => merchant.id === selectedMerchantId.value
  );
  if (selectedMerchant) {
    selectedMerchantName.value = selectedMerchant.name;
  }
  // Update the API URL when the user selects a merchant
  apiurl.value = `/api/client/users?id=${selectedMerchantId.value}`;
  // Refresh the data in the AgGrid
  agref.value.refreshAPI(apiurl.value);
};

// Function to load merchants from the API
const loadMerchants = async () => {
  try {
    const response = await http.get('/api/clients');
    if (response.data.status) {
      const unsortedMerchants = response.data.message.map((merchant) => ({
        id: merchant[0],
        name: merchant[1] || merchant[3]
      }));

      // Sort the merchants array alphabetically by name
      merchants.value = unsortedMerchants
        .slice()
        .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
    }
  } catch (error) {
    console.error('Error loading merchants:', error);
  }
};

onMounted(() => {
  loadMerchants();
});

// for code navigation - this is what it should have been called
// eslint-disable-next-line no-unused-vars
function startEditingUser() {
}

/**
 * @param {UserAgGridParams} params
 */
const setEditingUser = async (params) => {
  params.data.account.memberships = params.data.account.memberships || [];
  params.data.account.memberships.forEach(membership => {
    // the backend omits this field from JSON response when null
    // vue needs this object for use in v-model for role selection
    membership.role = membership.role || { id: null, name: null };
  })
  editingUser.value = params.data;
  console.log("editingUser.value: ", editingUser.value);
  isEditingUserModalOpen.value = true;
  const theMerchantId = params.data?.account?.default_client?.merchant?.id || null;
  if (theMerchantId) {
    defaultClientId.value = theMerchantId;
    selectedMerchantId.value = theMerchantId;
    // await getEditableRolesBySelectedMerchantId(theMerchantId);
    merchantForFilteringAvailableUserRoles.value = params.data?.account?.default_client?.merchant;
  }
}

const columnDefs = [
  {
    headerName: 'Name',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return `${params.data.account.first_name} ${params.data.account.last_name}`;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Email',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.email;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Role',
    filter: 'agSetColumnFilter',
    /**
     * @param {{ data: EditingUser }} params
     */
    valueGetter: (params) => {
      /*
      if (!params.data || !params.data.role) return '';
      return `${params.data.role.name} (${params.data.role.merchant.name})`;
      */
      let rolesFromMemberships = params.data.account?.memberships?.reduce((acc, next) => {
        // bunch up the names on the roles that you have from memberships
        return (!next.role?.name || acc.includes(next.role.name)) ? acc : acc.concat([next.role.name]);
      }, []).join(',') || '';

      // if you don't have those, try returning the name on the users default role (deprecated)
      return rolesFromMemberships || params.data.role?.name;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  /*
  {
    headerName: 'Merchant Role',
    filter: 'agSetColumnFilter',
    /**
     * @param {UserAgGridParams} params
     *
     /
    valueGetter: (params) => {
      if (!params.data || !params.data.role) return '';
      return params.data.role.merchant.name;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  */
  /*
  {
    headerName: 'Default Merchant',
    filter: 'agSetColumnFilter',
    /**
     * @param {UserAgGridParams} params
     * /
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.account.default_client.merchant.name || '';
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  */
  {
    headerName: 'Merchants',
    filter: 'agSetColumnFilter',
    /**
     * @param {UserAgGridParams} params
     */
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.account.memberships
        ?.map(e => e.role?.id ? `${e.merchant.name} (${e.role.name})` : e.merchant.name)
        .join(', ') || '';
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Locations',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data || !params.data.sub_entities) return '';
      return params.data.sub_entities.map((group) => group?.name).filter(Boolean).join(', ');
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Created At',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.created_at;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Updated At',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.updated_at;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Phone',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => {
      if (!params.data) return '';
      return params.data.account.phone;
    },
    onCellClicked: async function (params) {
      await setEditingUser(params);
    }
  },
  {
    headerName: 'Delete',
    filter: false,
    field: 'delete',
    cellRenderer: function (params) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-person-x-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6.146-2.854a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"/>
            </svg>`;
    },
    onCellClicked: function (params) {
      console.log(params);
      console.log('params are above for onCellClicked in users context');
      //console.log(params.node.data[3]);
      toast(
        {
          component: Confirm,
          listeners: {
            confirm: () => {
              http
                .delete(`/api/user/delete?id=${params.node.data.id}`)
                .then((response) => {
                  if (response.status === 200) {
                    console.log('Success', response);

                    toast.clear();
                    toast.success('Successfully deleted!', toastdefault);
                    agref.value.refreshAPI(apiurl.value);
                  } else {
                    console.error('Failed to delete');
                  }
                })
                .catch((error) => {
                  console.error('An error occurred while deleting:', error);
                });
            }
          }
        },
        toastdefault
      );
    }
  }
];

const showAddUserModal = () => {
  selectedMerchantId.value = '';
  modalInstance.value = new Modal(myModal.value);
  modalInstance.value.show();
};

const submitData = computed(() => {
  const data = {
    name: userName.value,
    email: email.value.toLowerCase(),
    user_type: 'user',
    label: '',
    locations: userSubLocations.value.map(s => s.id),
    // use the merchant which was selected, or if none, use same merchant as logged-in user
    merchant_id: selectedMerchantId.value || defaultClientid.value,
    role: userRole.value,
    disabled: false
  };

  return data;
});

const submitNewUser = () => {
  const data = submitData.value;
  console.log('Submitting user data', data);
  toast(
    {
      component: Confirm,
      listeners: {
        confirm: () => {
          http
            .post(`/api/user`, data)
            .then((response) => {
              if (response.status === 200) {
                console.log('Success', response);
                userRole.value = '';
                userSubLocationsSet.value.clear();
                selectedUserSubLocation.value = '';
                if (modalInstance.value) {
                  modalInstance.value.hide();
                }
                toast.success('Successfully added!', toastdefault);
                email.value = '';
                userName.value = '';
                user_type.value = '';
                label.value = '';
                agref.value.refreshAPI(apiurl.value);
              } else {
                console.error('Failed to add');
                try {
                  toast.error('Did not save', toastdefault)
                } catch (e) {
                  console.error('not able to toast.error in UsersPage:', e);
                }
              }
            })
            .catch((error) => {
              console.error('An error occurred while adding:', error);
            });
        }
      }
    },
    toastdefault
  );
};


const submitEditUserData = computed(() => {
  const data = {
    id: editingUser.value.id,
    name: userName.value,
    email: email.value.toLowerCase(),
    user_type: 'user',
    label: '',
    locations: userSubLocations.value,
    merchant_id: defaultClientid.value,
    role: userRole.value,
    disabled: false
  };

  return data;
});

const submitEditUser = () => {
  // const data = submitEditUserData.value;
  const data = JSON.stringify(editingUser.value);
  console.log('Submitting user data', data);
  toast(
      {
        component: Confirm,
        listeners: {
          confirm: () => {
            http
                .patch(`/api/user/update`, data, {
                  headers: {
                    'Content-Type': 'application/json',
                  }
                })
                .then((response) => {
                  if (response.status === 200) {
                    console.log('Success', response);
                    userRole.value = '';
                    userSubLocationsSet.value.clear();
                    selectedUserSubLocation.value = '';
                    if (modalInstance.value) {
                      modalInstance.value.hide();
                    }
                    toast.success('Successfully added!', toastdefault);
                    email.value = '';
                    userName.value = '';
                    user_type.value = '';
                    label.value = '';
                    agref.value.refreshAPI(apiurl.value);
                  } else {
                    console.error('Failed to add');
                  }
                })
                .catch((error) => {
                  console.error('An error occurred while adding:', error);
                });
          }
        }
      },
      toastdefault
  );
};

const filterModified = (model) => {
  console.log('USERS: filterModified function called with model: ', model);
};
</script>

<style lang="scss" scoped>
.title {
  color: #383838;
  font-size: 24px;
}
.loader {
  z-index: 2;
}

.add-user {
  height: 40px;
  padding: 0px 30px;
}

.search-dropdown {
  min-width: 200px;
}

.settings-btn {
  height: 40px;
  padding-top: 7px !important;
  border-radius: 5px !important;

  span {
    vertical-align: middle;
  }
}

.settings-link {
  background-color: var(--c-primary);
  color: var(--c-white);
  border-radius: 5px;
  text-decoration: none;
  height: 40px;
  padding: 7px 12px 6px;
  display: inline-block;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7a540;
  }
}

.clients {
  .actions {
    height: 100% !important;
  }

  .action-btn {
    line-height: initial;
    width: 30px !important;
    height: 30px !important;
    padding: 2px 1px !important;
    font-size: 14px !important;
    border-radius: 50% !important;

    .icon-container {
      width: auto !important;

      svg {
        width: 19px !important;
      }
    }

    &.success {
      color: #fff !important;
    }
  }
}
.modal-header {
  background-color: #6c757d;
  color: white;
  border-bottom: none;
}

.modal-header .modal-title {
  font-size: 20px;
  font-weight: 700;
}

.modal-body {
  padding: 20px;
}

.modal-body h6 {
  font-size: 18px;
  font-weight: 500;
}

.modal-body p a {
  color: black;
  text-decoration: underline;
}

.modal-body .form-group label {
  font-weight: 600;
}
</style>
